import React from "react";
import { AdminEdit } from "./AdminEdit";
import { Modal, ModalBody } from "reactstrap";

export function AdminEditModal({ client, viewType, isVisible, onToggle }) {
  return (
    <Modal
      isOpen={isVisible}
      toggle={onToggle}
      size="lg"
      style={{ wdith: "75%" }}
      scrollable
      centered
    >
      <ModalBody>
        <AdminEdit client={client} viewType={viewType} onToggle={onToggle} />
      </ModalBody>
    </Modal>
  );
}
