import React, { useState } from "react";
import { utilsService } from "../../services/utils";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Col,
  CustomInput,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { signup } from "../../store/actions/userAction";
import { userService } from "../../services/userService";
//import SweetAlert from "react-bootstrap-sweetalert";
import ReactBSAlert from "react-bootstrap-sweetalert";

export function GenericForm({ type }) {
  const dispatch = useDispatch();
  const [alertState, setAlertState] = useState(null);
  const [alertState2, setAlertState2] = useState(null);

  //form inputs state
  const [emailState, setEmailState] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [fullNameState, setFullNameState] = useState("");
  const [managerTypeState] = useState("");

  //form inputs
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [managerType, setManagerType] = useState("off");
  const [showPassword, setShowPassword] = useState(false);

  const registerClick = () => {
    if (emailState === "") {
      setEmailState("has-danger");
    }
    if (passwordState === "") {
      setPasswordState("has-danger");
    }

    if (fullNameState === "") {
      setFullNameState("has-danger");
    } else doSignUp();
  };

  const doSignUp = async () => {
    if (!email || !password || !fullName) {
      alertEvent("Please fill all fields");
      return;
    }
    const test = await userService.getById(email);
    if (test) {
      alertEvent("Cet email existe déjà");
      return;
    }

    const userObj = await _createUser();
    dispatch(signup(userObj));
    alertEvent2("Utilisateur créé");
  };

  const _createUser = async () => {
    const obj = {
      userType: type,
      fullName: fullName,
      email: email,
      password: password,
      logo: "",
    };

    if (type === "zoneManager") {
      obj.zoneType = managerType === "on" ? "external" : "internal";
    }
    return obj;
  };

  const handleUserTypeChange = () => {
    setManagerType(managerType === "on" ? "off" : "on");
  };

  const alertEvent = (title) => {
    setAlertState(
      <ReactBSAlert
        warning
        style={{ display: "inline", marginTop: "-100px" }}
        title={title}
        onConfirm={() => setAlertState(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="X"
      >
        {title}
      </ReactBSAlert>
    );
  };

  const alertEvent2 = (title) => {
    setAlertState2(
      <ReactBSAlert
        success
        style={{ display: "inline", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          setAlertState2(null);
          window.location.assign("/#/dashboard");
        }}
        confirmBtnBsStyle="primary"
        confirmBtnText="J'ai compris!"
      >
        {title}
      </ReactBSAlert>
    );
  };

  return (
    <>
      {alertState}
      {alertState2}
      <div className="gen-form">
        <Col bg="10">
          <Form id="RegisterValidation">
            <Card className="generic-signup-card">
              <CardBody>
                <FormGroup className={`has-label ${fullNameState}`}>
                  <label>Nom complet *</label>
                  <Input
                    placeholder="Nom Prenom"
                    autoComplete="off"
                    name="email"
                    type="email"
                    onChange={(ev) => {
                      setFullName(ev.target.value);
                      if (utilsService.verifyLength(ev.target.value, 2)) {
                        setFullNameState("has-success");
                      } else {
                        setFullNameState("has-danger");
                      }
                    }}
                  />
                  {fullNameState === "has-danger" ? (
                    <label className="error">
                      Please enter a valid email address.
                    </label>
                  ) : null}
                </FormGroup>

                {type === "zoneManager" && (
                  <FormGroup className={`has-label ${managerTypeState}`}>
                    <label>Type de gestionnaire *</label>
                    <div className="flex">
                      <label>Gestionnaire Servelite</label>
                      <CustomInput
                        type="switch"
                        name="managerType"
                        value={managerType}
                        id="switch-1"
                        label="Gestionnaire non couvert Servelite"
                        onChange={handleUserTypeChange}
                      />
                    </div>
                  </FormGroup>
                )}

                <FormGroup className={`has-label ${emailState}`}>
                  <label>Email *</label>
                  <Input
                    autoComplete="off"
                    name="email"
                    type="email"
                    onChange={(ev) => {
                      setEmail(ev.target.value);
                      if (utilsService.verifyEmail(ev.target.value)) {
                        setEmailState("has-success");
                      } else {
                        setEmailState("has-danger");
                      }
                    }}
                  />
                  {emailState === "has-danger" ? (
                    <label className="error">
                      Merci de renseigner un email valide.
                    </label>
                  ) : null}
                </FormGroup>

                <FormGroup className={`has-label ${passwordState}`}>
                  <label>Mot de passe *</label>
                  <div className="password-input-wrapper">
                    <i
                      onClick={() => setShowPassword((old) => !old)}
                      className={`fas ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                    <Input
                      autoComplete="off"
                      id="registerPassword"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={(ev) => {
                        setPassword(ev.target.value);
                        if (utilsService.verifyLength(ev.target.value)) {
                          setPasswordState("has-success");
                        } else {
                          setPasswordState("has-danger");
                        }
                      }}
                    />
                  </div>
                  {passwordState === "has-danger" ? (
                    <label className="error">
                      Merci de renseigner un mot de passe valide.
                    </label>
                  ) : null}
                </FormGroup>
              </CardBody>
              <CardFooter className="text-right">
                <Button
                  className="signup-btn"
                  color="success"
                  onClick={registerClick}
                >
                  Enregistrer
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </div>
    </>
  );
}
