const initialState = {
  combos: [],
};

export function comboReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_COMBOS":
      return {
        ...state,
        combos: action.combos,
      };

    case "CLEAR_COMBOS":
      return { ...initialState };

    default:
      return state;
  }
}
