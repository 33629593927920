import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { Button } from "reactstrap";
const cgvUrl =
  "https://serveilte.fra1.digitaloceanspaces.com/serveliteLegalMention.pdf";

function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function LegalMention() {
  const [pageTotal, setPageTotal] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);

  useEffect(() => {
    if (pageTotal) {
      setHasPrev(pageNumber - 1 > 0);
      setHasNext(pageNumber + 1 < pageTotal);
    }
  }, [pageNumber, pageTotal]);

  const onLoadSuccess = ({ numPages }) => {
    setPageTotal(numPages);
  };
  const onLoadError = (error) => {
    console.error(error);
  };

  const handlePrev = () => {
    if (!hasPrev) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (!hasNext) return;
    setPageNumber(pageNumber + 1);
  };

  return (
    <div id="legal-mention">
      <div className="pdf-container">
        <div className="pdf-header">
          <h2>CGV</h2>
          <Button color="info" onClick={() => downloadURI(cgvUrl, "CGV.pdf")}>
            <i className="fa fa-download" /> Télécharger
          </Button>
        </div>
        <div className="pdf-content">
          <Document
            file={cgvUrl}
            onLoadSuccess={onLoadSuccess}
            onLoadError={onLoadError}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
        <p className="pdf-footer">
          {hasPrev && <i className="fa fa-arrow-left" onClick={handlePrev} />}
          Page {pageNumber} / {pageTotal}
          {hasNext && <i className="fa fa-arrow-right" onClick={handleNext} />}
        </p>
      </div>
    </div>
  );
}
