import { comboService } from "../../services/comboService";

export function loadCombos() {
  return async (dispatch) => {
    const combos = await comboService.getAll();
    dispatch({ type: "SET_COMBOS", combos });
  };
}

export function clearCombos() {
  return async (dispatch) => {
    dispatch({ type: "CLEAR_COMBOS" });
  };
}
