import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../store/actions/userAction";
import { Button, Card, CardImg, CardBody } from "reactstrap";

export function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  /*login cred*/
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [showFlag, setShowFlag] = useState(false);
  const [error, setError] = useState(null);

  const onLoginHandleChange = (ev) => {
    const { name, value } = ev.target;
    if (name === "log-mail") setLoginEmail(value);
    if (name === "log-pass") setLoginPassword(value);
  };

  const doLogin = async (ev) => {
    setError(null);
    ev.preventDefault();
    if (!loginEmail || !loginPassword) {
      alert("Please enter email/password");
      return;
    }

    const userCred = {
      email: loginEmail.toLowerCase(),
      password: loginPassword,
    };

    const result = await dispatch(login(userCred));
    if (result.ok) {
      localStorage.setItem("isAuthenticated", true);
      history.replace("dashboard");
    } else setError(result.error);
  };

  const onChangeShowIcon = () => {
    setShowFlag(!showFlag);
  };

  return (
    <div className="inputs-container flex card-primary login-card-container">
      {/*LOG IN FORM*/}
      <Card style={{ width: "40rem" }} className="login-card">
        <CardImg
          top
          src={require("../assets/img/card-success.png").default}
          alt="..."
        />
        <CardBody className="card-body login-cardBody">
          <h2>LinkWell</h2>
          <form onSubmit={doLogin}>
            <div className="flex-col log-inputs-container">
              {error && <p className="error">{error}</p>}
              <input
                type="text"
                name="log-mail"
                className="loginInput form-control"
                value={loginEmail}
                onChange={onLoginHandleChange}
                placeholder="Email"
              />
              <input
                type={!showFlag ? "password" : "text"}
                className="loginInput pass-login-input form-control"
                name="log-pass"
                value={loginPassword}
                onChange={onLoginHandleChange}
                placeholder="Password"
              />
              {!showFlag && (
                <i
                  onClick={onChangeShowIcon}
                  className="fas fa-eye pass-icon"
                ></i>
              )}
              {showFlag && (
                <i
                  onClick={onChangeShowIcon}
                  className="fas fa-eye-slash pass-icon"
                ></i>
              )}
            </div>
            <div className="flex-col login-btn-container">
              <Button
                color="success"
                type="submit"
                className="animation-on-hover"
                onClick={doLogin}
              >
                Log in
              </Button>
              <CardImg
                top
                className="servelit-logo"
                src={require("../assets/img/linkWell1.png").default}
                alt="..."
              />
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}
