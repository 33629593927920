import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useDispatch } from "react-redux";
import { signup } from "../../store/actions/userAction";
import { utilsService } from "../../services/utils";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { userService } from "../../services/userService";
import { localStorageService } from "../../services/localStorageService";
import { installatorService } from "../../services/installatorService";
import { t } from "../../constants/text";
import { AddInstallator } from "../AddInstallator";
import { eventBus } from "../../services/eventBusService";

export function Installator() {
  const dispatch = useDispatch();
  const animatedComponents = makeAnimated();
  const [alertState, setAlertState] = useState(null);
  const [alertState2, setAlertState2] = useState(null);
  const [theme, setTheme] = useState(null);
  const [addFlag, setAddFlag] = useState(false);
  //form inputs state
  const [emailState, setEmailState] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [companyNameState, setCompanyNameState] = useState("");
  const [fullNameState, setFullNameState] = useState("");
  const [adressState, setAdressState] = useState("");
  const [telephoneState, setTelephoneState] = useState("");
  const [mobileState, setMobileState] = useState("");
  const [sirenState, setSirenState] = useState("");

  //form inputs
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [fullName, setFullName] = useState("");
  const [adress, setAdress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [mobile, setMobile] = useState("");
  const [siren, setSiren] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [installatorOptions, setInstallatorOptions] = useState([]);

  useEffect(() => {
    let mount = true;
    if (mount) {
      const userPref = localStorageService.loadFromStorage("theme");
      //console.log('vnfvnkfk',userPref.theme);
      if (userPref) setTheme(userPref.theme);
      getInstallators();
      registerEventBusListeners();
    }
    return () => {
      removeEventBusListeners();
      mount = false;
    };
    // eslint-disable-next-line
  }, []);

  async function removeEventBusListeners() {
    eventBus.removeMultiple(["closeAfterAdded", "closeAddInstallator"]);
  }

  const registerEventBusListeners = async () => {
    removeEventBusListeners();
    eventBus.on("closeAfterAdded", () => {
      getInstallators();
      setAddFlag(false);
    });
    eventBus.on("closeAddInstallator", () => {
      setAddFlag(false);
    });
  };

  const getInstallators = async () => {
    const usersData = await installatorService.query();
    const instOptions = usersData.map((user) => {
      return {
        value: user.companyName,
        label: user.companyName,
      };
    });
    setInstallatorOptions(instOptions);
  };

  const registerClick = () => {
    if (emailState === "") {
      setEmailState("has-danger");
    }
    if (passwordState === "") {
      setPasswordState("has-danger");
    }
    if (telephoneState === "") {
      setTelephoneState("has-danger");
    }

    if (companyNameState === "") {
      setCompanyNameState("has-danger");
    }

    if (fullNameState === "") {
      setFullNameState("has-danger");
    }

    if (mobileState === "") {
      setMobileState("has-danger");
    }
    if (sirenState === "") {
      setSirenState("has-danger");
    }

    if (adressState === "") {
      setAdressState("has-danger");
    } else doSignUp();
  };

  const doSignUp = async () => {
    if (
      !email ||
      !password ||
      !telephone ||
      !fullName ||
      !companyName ||
      !mobile ||
      !adress ||
      !siren
    ) {
      alertEvent("Tous les champs sont obligatoires");
      return;
    }
    const test = await userService.getById(email);
    if (test) {
      alertEvent("Cet email existe déjà");
      return;
    }
    const userObj = await _createUser();
    dispatch(signup(userObj));
    alertEvent2("Utilisateur créé");
  };

  const _createUser = async () => {
    const obj = {
      userType: "installator",
      companyName: companyName,
      siren: siren,
      fullName: fullName,
      adress: adress.label,
      codePostal: await _getCodePostal(),
      telephone: telephone,
      mobile: mobile,
      email: email,
      password: password,
      logo: "",
      legalMention: false,
    };

    return obj;
  };

  const _getCodePostal = async () => {
    const data = await geocodeByPlaceId(adress.value.place_id);
    return data[0].address_components[data[0].address_components.length - 1]
      .long_name;
  };

  const handleAdressChange = (value) => {
    setAdress(value);
    setAdressState("has-success");
  };

  const alertEvent = (title) => {
    setAlertState(
      <ReactBSAlert
        warning
        style={{ display: "inline", marginTop: "-100px" }}
        title={title}
        onConfirm={() => setAlertState(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="X"
      >
        {title}
      </ReactBSAlert>
    );
  };

  const alertEvent2 = (title) => {
    setAlertState2(
      <ReactBSAlert
        success
        style={{ display: "inline", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          setAlertState2(null);
          window.location.assign("/#/dashboard");
        }}
        confirmBtnBsStyle="primary"
        confirmBtnText="J'ai compris!"
      >
        {title}
      </ReactBSAlert>
    );
  };

  const openAddInstallatorModal = () => {
    setAddFlag(true);
  };

  return (
    <>
      {alertState}
      {alertState2}
      {addFlag && <AddInstallator theme={theme} />}
      <div className="content">
        <Col>
          <Form id="RegisterValidation" className="RegisterValidation">
            <Card className="inst-signup-card">
              <CardBody>
                {/* <FormGroup className={`has-label ${companyNameState}`}>
                  <label>Societe *</label>
                  <Input
                    autoComplete="off"
                    name="societe"
                    type="text"
                    value={companyName}
                    onChange={(ev) => {
                      setCompanyName(ev.target.value);
                      if (utilsService.verifyLength(ev.target.value, 2)) {
                        setCompanyNameState("has-success");
                      } else {
                        setCompanyNameState("has-danger");
                      }
                    }}
                  />
                  {companyNameState === "has-danger" ? (
                    <label className="error">
                      Merci de renseigner un nom de société valide.
                    </label>
                  ) : null}
                </FormGroup> */}

                <FormGroup>
                  <label htmlFor="">Societe *</label>
                  <div className="flex-around">
                    <Select
                      noOptionsMessage={() => t.NoOptions}
                      className={
                        theme
                          ? "myLightMode3 my-select addSelect"
                          : "my-select addSelect"
                      }
                      components={animatedComponents}
                      options={installatorOptions}
                      onChange={(selectedProduct) => {
                        setCompanyName(selectedProduct.label);
                      }}
                    />
                    <section
                      className={
                        theme ? "addSection flex addTheme" : "addSection flex"
                      }
                    >
                      <i
                        className="tim-icons icon-simple-add addIcon"
                        onClick={openAddInstallatorModal}
                      />
                      {/* <span>+</span> */}
                    </section>
                  </div>
                </FormGroup>

                <FormGroup className={`has-label ${sirenState}`}>
                  <label>Siren *</label>
                  <Input
                    autoComplete="off"
                    name="siren"
                    type="number"
                    value={siren}
                    onChange={(ev) => {
                      setSiren(ev.target.value);
                      if (utilsService.verifyLength(ev.target.value, 9)) {
                        setSirenState("has-success");
                      } else {
                        setSirenState("has-danger");
                      }
                    }}
                  />
                  {fullNameState === "has-danger" ? (
                    <label className="error">
                      Merci de renseigner un nom du correspondant valide.
                    </label>
                  ) : null}
                </FormGroup>

                <FormGroup className={`has-label ${fullNameState}`}>
                  <label>Nom du correspondant *</label>
                  <Input
                    autoComplete="off"
                    name="email"
                    type="email"
                    aria-autocomplete="none"
                    autoCapitalize="off"
                    autoCorrect="off"
                    value={fullName}
                    onChange={(ev) => {
                      setFullName(ev.target.value);
                      if (utilsService.verifyLength(ev.target.value, 2)) {
                        setFullNameState("has-success");
                      } else {
                        setFullNameState("has-danger");
                      }
                    }}
                  />
                  {fullNameState === "has-danger" ? (
                    <label className="error">
                      Merci de renseigner un nom du correspondant valide.
                    </label>
                  ) : null}
                </FormGroup>

                <FormGroup className={`has-label  adress`}>
                  <label>Adresse *</label>
                  <div className={theme ? "myLightMode" : ""}>
                    <GooglePlacesAutocomplete
                      autocompletionRequest={{
                        componentRestrictions: { country: "fr" },
                      }}
                      apiOptions={{ language: "fr", region: "fr" }}
                      selectProps={{
                        placeholder: "....",
                        value: adress,
                        onChange: handleAdressChange,
                      }}
                    />
                  </div>
                  {adressState === "has-danger" ? (
                    <label className="error">
                      Merci de renseigner une adresse valide.
                    </label>
                  ) : null}
                </FormGroup>

                <FormGroup className={`has-label ${telephoneState}`}>
                  <label>Téléphone *</label>
                  <Input
                    autoComplete="off"
                    name="telephone"
                    type="number"
                    maxLength={13}
                    value={telephone}
                    onChange={(ev) => {
                      setTelephone(ev.target.value);
                      if (utilsService.verifyPhoneNum(ev.target.value)) {
                        setTelephoneState("has-success");
                      } else {
                        setTelephoneState("has-danger");
                      }
                    }}
                  />
                  {telephoneState === "has-danger" ? (
                    <label className="error">
                      Merci de renseigner un téléphone valide.
                    </label>
                  ) : null}
                </FormGroup>

                <FormGroup className={`has-label ${mobileState}`}>
                  <label>Portable *</label>
                  <Input
                    autoComplete="off"
                    name="telephone"
                    value={mobile}
                    maxLength={13}
                    type="number"
                    onChange={(ev) => {
                      setMobile(ev.target.value);
                      if (utilsService.verifyPhoneNum(ev.target.value)) {
                        setMobileState("has-success");
                      } else {
                        setMobileState("has-danger");
                      }
                    }}
                  />
                  {mobileState === "has-danger" ? (
                    <label className="error">
                      Merci de renseigner un portable valide.
                    </label>
                  ) : null}
                </FormGroup>
                <FormGroup className={`has-label ${emailState}`}>
                  <label>Email *</label>
                  <Input
                    autoComplete="off"
                    name="email"
                    value={email}
                    type="email"
                    onChange={(ev) => {
                      setEmail(ev.target.value);
                      if (utilsService.verifyEmail(ev.target.value)) {
                        setEmailState("has-success");
                      } else {
                        setEmailState("has-danger");
                      }
                    }}
                  />
                  {emailState === "has-danger" ? (
                    <label className="error">
                      Merci de renseigner un email valide.
                    </label>
                  ) : null}
                </FormGroup>

                <FormGroup className={`has-label ${passwordState}`}>
                  <label>Mot de passe *</label>
                  <div className="password-input-wrapper">
                    <i
                      onClick={() => setShowPassword((old) => !old)}
                      className={`fas ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                    <Input
                      autoComplete="off"
                      id="registerPassword"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(ev) => {
                        setPassword(ev.target.value);
                        if (utilsService.verifyLength(ev.target.value)) {
                          setPasswordState("has-success");
                        } else {
                          setPasswordState("has-danger");
                        }
                      }}
                    />
                  </div>
                  {passwordState === "has-danger" ? (
                    <label className="error">
                      Merci de renseigner un mot de passe valide.
                    </label>
                  ) : null}
                </FormGroup>
              </CardBody>
              <CardFooter className="text-right">
                <Button
                  color="success"
                  className="signup-btn"
                  onClick={registerClick}
                >
                  Enregistrer
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </div>
    </>
  );
}
