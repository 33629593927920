import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import { loadProducts } from "../../../store/actions/productAction";
import Select from "react-select";
import { localStorageService } from "../../../services/localStorageService";
import { t } from "../../../constants/text";

// const animatedComponents = makeAnimated();

const defaultRow = {
  familyId: undefined,
  productId: undefined,
  model: undefined,
  quantity: 1,
};

const nullOption = { value: undefined, label: "Selectionner" };

const Step2 = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const loggedInUser =
    props.wizardData.Informations && props.wizardData.Informations.loggedInUser;
  const isInstallator =
    loggedInUser && loggedInUser?.userType === "installator";
  const companyId = isInstallator && loggedInUser?.companyId;
  const families = useSelector((state) => state.productReducer.products);
  const [rows, setRows] = useState(
    props.wizardData.Informations || [{ ...defaultRow }]
  );
  const [theme, setTheme] = useState(null);
  const [error, setError] = useState("");

  React.useEffect(() => {
    let mount = true;
    if (mount) {
      dispatch(loadProducts());
      const userPref = localStorageService.loadFromStorage("theme");
      if (userPref) setTheme(userPref.theme);
    }
    return () => {
      mount = false;
    };
    // eslint-disable-next-line
  }, []);

  /*eslint-disable-next-line*/
  const isValidated = () => {
    if (rows.length < 1) {
      setError("Vous devez selectionner au moins un produit");
      return false;
    } else {
      let e = [];
      rows.forEach((r, index) => {
        if (!r.familyId || !r.productId || !r.quantity) {
          e.push(`Ligne ${index + 1} incomplète`);
        }
      });
      if (e && e.length > 0) {
        setError(e.join(", "));
        return false;
      }
    }

    return true;
  };

  // Add Remove Rows
  const addRow = () => {
    setRows((old) => [...old, { ...defaultRow }]);
  };

  const removeRow = (idx) => {
    setRows((old) => old.filter((row, index) => idx !== index));
  };

  // Changes
  const handleChange = (index, field, value) => {
    const newRows = [...rows];
    setError(undefined);
    let selectedRow = newRows[index];
    if (selectedRow) {
      newRows[index][field] = value;
      if (field === "familyId") {
        newRows[index]["productId"] = undefined;
        newRows[index]["model"] = undefined;
      }
      if (field === "productId") {
        newRows[index]["model"] = undefined;
      }
      setRows(newRows);
    }
  };

  // Save
  const saveData = () => {
    if (!families || !loggedInUser) return;
    const data = [];
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const { familyId, productId } = row;
      const family = families.find((f) => f._id === familyId);
      if (family) {
        const product = family.products.find((p) => p.id === productId);
        if (product) {
          const installatorPrices = product?.installators_prices;
          let price = product.price;
          if (
            isInstallator &&
            installatorPrices &&
            installatorPrices.length > 0
          ) {
            const ip = installatorPrices.find(
              (isp) => isp.installatorId === companyId
            );
            if (ip) price = ip.price;
          }

          data.push({
            ...row,
            familyName: family?.family || "N/A",
            productName: product?.name || "N/A",
            productPrice: price,
          });
        }
      }
    }
    console.log("savedData", data);
    return data;
  };
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state: saveData(),
  }));

  if (!families) return <div>Chargement...</div>;

  const getFamilies = () => {
    return [
      nullOption,
      ...families.map((family) => ({
        value: family._id,
        label: family.family,
      })),
    ];
  };
  const getProducts = (familyId) => {
    if (!familyId) return [nullOption];

    const family = families.find((f) => f._id === familyId);
    if (!family) return [nullOption];

    const options = family.products.map((p) => ({
      value: p.id,
      label: p.name,
    }));
    return [nullOption, ...options];
  };
  const getModels = (familyId, productId) => {
    if (!familyId) return [nullOption];
    if (!productId) return [nullOption];

    const family = families.find((f) => f._id === familyId);
    if (!family) return [nullOption];

    const product = family.products.find((p) => p.id === productId);
    if (!product) return [];

    const options = product.models.map((m) => ({ value: m, label: m }));

    return [nullOption, ...options];
  };
  const getQuantity = () => {
    const options = [];
    for (let i = 1; i < 5; i++) {
      options.push({ label: i, value: i });
    }
    return options;
  };

  return (
    <div className="flex-col">
      <h5 className="info-text">CHOISIR UN PRODUIT</h5>
      {rows.map((row, index) => {
        const rowFamilies = [...getFamilies()];
        const rowProducts = [...getProducts(row.familyId)];
        const rowModels = [...getModels(row.familyId, row.productId)];
        const rowQuantity = [...getQuantity()];

        const selectedFamily =
          rowFamilies.find((f) => f.value === row.familyId) || nullOption;
        const selectedProduct =
          rowProducts.find((f) => f.value === row.productId) || nullOption;
        const selectedModel =
          rowModels.find((f) => f.value === row.model) || nullOption;
        const selectedQuantity =
          rowQuantity.find((f) => f.value === row.quantity) || nullOption;
        return (
          <Row key={index} className="" style={{ marginBottom: "1rem" }}>
            <Col className="step2Inputs flex-between" bg="2">
              <Select
                noOptionsMessage={() => t.NoOptions}
                className={
                  theme
                    ? "myLightMode2 my-select myFontSize"
                    : "my-select myFontSize"
                }
                placeholder="Sélectionnez la famille du produit"
                options={rowFamilies}
                value={selectedFamily}
                onChange={(e) => handleChange(index, "familyId", e.value)}
              />

              <Select
                noOptionsMessage={() => t.NoOptions}
                className={
                  theme
                    ? "myLightMode2 my-select myFontSize"
                    : "my-select myFontSize"
                }
                placeholder="Sélectionnez la categorie ou marque du produit"
                options={rowProducts}
                value={selectedProduct}
                onChange={(e) => handleChange(index, "productId", e.value)}
              />

              <Select
                noOptionsMessage={() => t.NoOptions}
                className={
                  theme
                    ? "myLightMode2 my-select myFontSize"
                    : "my-select myFontSize"
                }
                placeholder="Sélectionnez votre puissance de produit"
                options={rowModels}
                value={selectedModel}
                onChange={(e) => handleChange(index, "model", e.value)}
              />

              <Select
                noOptionsMessage={() => t.NoOptions}
                className={
                  theme
                    ? "myLightMode2 my-select myFontSize"
                    : "my-select myFontSize"
                }
                placeholder="Quantité"
                options={rowQuantity}
                value={selectedQuantity}
                onChange={(e) => handleChange(index, "quantity", e.value)}
              />
              <Button
                className="btn-simple"
                onClick={() => removeRow(index)}
                color="danger"
              >
                X{" "}
              </Button>
            </Col>
          </Row>
        );
      })}
      <Button className="addProduct-btn" onClick={addRow} color="success">
        Ajout produit supplementaire
      </Button>
      {error && <p className="error">{error}</p>}
    </div>
  );
});

export default Step2;
