import ClientsTable from '../cmps/ClientsTable';
import { ExportTable } from '../pages/ExportTable';
import { Links } from '../pages/Links';
import { LegalMention } from '../pages/LegalMention';

const regRoutes = [
    {
      path: "/createClient",
      layout: "/dashboard",
      name: "NOUVEAU CLIENT",
      icon: "tim-icons icon-app",
      component: ClientsTable,
    },
    {
      collapse: false,
      path: "exportTable",
      layout: "dashboard/",
      name: "EXPORT EXCEL",
      icon: "tim-icons icon-cloud-download-93",
      component:ExportTable,
    },
    {
      collapse: false,
      path: "links",
      layout: "dashboard/",
      name: "Documentation",
      icon: "tim-icons icon-single-copy-04",
      component:Links,
    },
    {
      collapse: false,
      path: "LegalMention",
      layout: "dashboard/",
      name: "CGV",
      icon: "tim-icons icon-book-bookmark",
      component:LegalMention,
    },
  ]

  export default regRoutes;