import React, { useEffect } from "react";
import { CommentLine } from "./CommentLine";
import { useSelector, useDispatch } from "react-redux";
import { saveCrm } from "../store/actions/crmAction";
import { Modal, ModalBody } from "reactstrap";

export function ClientCommentsModal({ client, isVisible, onToggle }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);

  // Update read message for current users
  useEffect(() => {
    const { _id } = loggedInUser;
    const lastReads = client.lastReads ? [...client.lastReads] : [];
    let currentUserIndex = lastReads.findIndex((f) => f.userId === _id);
    const lastReadDate = Date.now();
    if (currentUserIndex > -1) {
      lastReads[currentUserIndex].date = lastReadDate;
    } else {
      lastReads.push({ userId: _id, date: lastReadDate });
    }
    client.lastReads = lastReads;
    dispatch(saveCrm(client));
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      isOpen={isVisible}
      toggle={onToggle}
      size="lg"
      className="client-comment-modal"
      centered
    >
      <ModalBody>
        <CommentLine
          crm={client}
          currentUser={loggedInUser}
          onClose={onToggle}
        />
      </ModalBody>
    </Modal>
  );
}
