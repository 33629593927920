import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { eventBus } from "../../services/eventBusService";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useDispatch } from "react-redux";
import { saveUser } from "../../store/actions/userAction";
import ImageUpload from "../../components/CustomUpload/ImageUpload.js";
import { fileService } from "../../services/fileService";
import SweetAlert from "react-bootstrap-sweetalert";
import { t } from "../../constants/text";

export function EditInstallator({ loggedInUser }) {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState(loggedInUser.fullName);
  const [adress, setAdress] = useState(loggedInUser.adress);
  const [codePostal, setCodePostal] = useState(loggedInUser.codePostal);
  const [telephone, setTelephone] = useState(loggedInUser.telephone);
  const [mobile, setMobile] = useState(loggedInUser.mobile);
  const [email, setEmail] = useState(loggedInUser.email);
  const [file, setFile] = useState(null);
  const [alertState, setAlertState] = useState(null);

  const alertEvent = (title) => {
    setAlertState(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          setAlertState(null);
          eventBus.emit("returnFromUserEdit");
        }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      ></SweetAlert>
    );
  };

  const onClickReturn = () => {
    eventBus.emit("returnFromUserEdit");
  };

  const _getCodePostal = async (adress) => {
    const data = await geocodeByPlaceId(adress.value.place_id);
    setCodePostal(
      data[0].address_components[data[0].address_components.length - 1]
        .long_name
    );
  };

  const handleAdressChange = (value) => {
    setAdress(value.label);
    _getCodePostal(value);
  };

  const onSubmitProfile = async () => {
    submitImage();
    loggedInUser.fullName = fullName;
    loggedInUser.adress = adress;
    loggedInUser.codePostal = codePostal;
    loggedInUser.telephone = telephone;
    loggedInUser.mobile = mobile;
    loggedInUser.email = email;
    loggedInUser.logo = file ? file.name : loggedInUser.logo;
    // loggedInUser.legalMention = loggedInUser.legalMention
    // loggedInUser.password = loggedInUser.password;
    dispatch(saveUser(loggedInUser));
    alertEvent("Vos détails ont été mises à jour avec succès !");
  };

  const submitImage = async () => {
    if (file) {
      const data = new FormData();
      data.append("file", file);
      await fileService.save(data);
    } else return;
  };

  const onHandleImageChange = (fileData) => {
    //console.log(fileData.name);
    fileData ? setFile(fileData) : setFile(null);
  };

  return (
    <>
      {alertState}
      <Row className="installProfile-container flex">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Profil</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label>Nom du correspondant</label>
                      <Input
                        value={fullName}
                        onChange={(ev) => {
                          setFullName(ev.target.value);
                        }}
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        value={email}
                        onChange={(ev) => {
                          setEmail(ev.target.value);
                        }}
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Téléphone</label>
                      <Input
                        value={telephone}
                        onChange={(ev) => {
                          setTelephone(ev.target.value);
                        }}
                        type="number"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Portable</label>
                      <Input
                        value={mobile}
                        onChange={(ev) => {
                          setMobile(ev.target.value);
                        }}
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label>Nom de l'entreprise</label>
                      <Input
                        defaultValue={loggedInUser.companyName}
                        disabled
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Adresse</label>
                      <GooglePlacesAutocomplete
                        autocompletionRequest={{
                          componentRestrictions: { country: "fr" },
                        }}
                        apiOptions={{ language: "fr", region: "fr" }}
                        selectProps={{
                          //placeholder: adress
                          noOptionsMessage: () => t.NoOptions,
                          defaultInputValue: adress,
                          onChange: handleAdressChange,
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="downInput">
                      <label>Code Postal</label>
                      <Input value={codePostal} disabled type="text" />
                    </FormGroup>
                    <FormGroup className="downInput2">
                      <label>Siren</label>
                      <Input value={loggedInUser.siren} disabled />
                    </FormGroup>
                  </Col>
                  <Col md="4" align="center">
                    <ImageUpload
                      addBtnColor="default"
                      changeBtnColor="default"
                      onHandleImageChange={onHandleImageChange}
                      loggedInUser={loggedInUser}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter className="flex-around">
              <Button
                onClick={onClickReturn}
                className="btn-fill ret-btn-editProfile"
                color="primary"
              >
                Retour
              </Button>
              <Button
                onClick={onSubmitProfile}
                className="btn-fill save-btn-editProfile"
                color="info"
              >
                Sauvegarder
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
}
