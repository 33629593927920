import React,{useState} from 'react';
import { eventBus } from '../services/eventBusService';
import { Input, Button } from 'reactstrap';
import { installatorService } from '../services/installatorService';
import SweetAlert from "react-bootstrap-sweetalert";

export function AddInstallatorPreview({theme}) {
    const [instName,setInstName] = useState('');
    const [alertState, setAlertState] = useState(null);

    const closeModal = () => {
        eventBus.emit("closeAddInstallator");
    };

    const alertEvent = (title) => {
        setAlertState(
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title={title}
            onConfirm={() => {
              setAlertState(null);
              eventBus.emit("closeAfterAdded");
            }}
            onCancel={() => setAlertState(null)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="danger"
            confirmBtnText="J'ai compris!"
          ></SweetAlert>
        );
      };

    const submitInstallatorName = async ()=> {
        if(!instName) return;
        const instObj = {
            companyName : instName
        }
        await installatorService.save(instObj);
        alertEvent('Nouvel installateur cree avec succes')
    }

    return (
        <div className="flex-col">
            {alertState}
            <div className={theme ? "lightHeader-add add-header-section flex-around" : "add-header-section flex-around"}>
                <h4 className={theme ? "lightH4" : ""}>Ajout d un installateur</h4>
                <i className="tim-icons icon-simple-remove close-icon2" onClick={closeModal} />
            </div>
            <div className="input-addInst-container flex-col">
                <label htmlFor="">* Nom de l installateur</label>
                <Input className="addInstallatorInput" value={instName} onChange={(ev)=> {
                    setInstName(ev.target.value)
                }} />
            </div>
            <hr />
            <div className="addInst-btn-container flex-between">
                <Button color="danger" onClick={closeModal}>FERMER</Button>
                <Button color="info" onClick={submitInstallatorName}>ENREGISTRER</Button>
            </div>
        </div>
    )
}
