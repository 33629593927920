const initialState = {
  crms: null,
};

export function crmReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CRMS":
      return {
        ...state,
        crms: action.crms,
      };

    case "REMOVE_CRM":
      return {
        ...state,
        crms: (state.crms || []).filter((crm) => crm._id !== action.crmId),
      };

    case "UPDATE_CRM":
      //console.log("update CRM action");
      const idx = state.crms.findIndex((crm) => crm._id === action.crm._id);
      const updatedCrms = [...state.crms];
      updatedCrms[idx] = action.crm;
      return { ...state, crms: updatedCrms };

    case "ADD_CRM":
      return { crms: [...(state.crms || []), action.crm], ...state };

    case "CLEAR_CRMS":
      return { ...initialState };

    default:
      return state;
  }
}
