import httpService from "./httpService";

export const crmService = {
  save,
  query,
  getById,
  remove,
};

function query(filterBy = {}) {
  let queryStr = "?";
  for (const key in filterBy) {
    queryStr += `${key}=${filterBy[key]}&`;
  }

  return httpService.get(`crm${queryStr || ""}`);
}

function getById(crmId) {
  //console.log(crmId);
  return httpService.get(`crm/${crmId}`);
}

function remove(crmId) {
  return httpService.delete(`crm/${crmId}`);
}

function save(crm) {
  //console.log(crm);
  if (crm._id) {
    return httpService.put(`crm/${crm._id}`, crm);
  } else {
    return httpService.post(`crm`, crm);
  }
}

// function checkIsEdit(crm) {
//  console.log(crm);
// }
