import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EditInstallator } from "./profileInputs/EditInstallator";
import { InstallatorProfile } from "./profileInputs/InstallatorProfile";
import { Manager } from "./profileInputs/Manager";
import { eventBus } from "../services/eventBusService";

export function UserProfile() {
  const [flag, setFlag] = useState(false);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);

  useEffect(() => {
    registerEventBusListeners();
    return () => {
      eventBus.removeMultiple(["openEditUser", "returnFromUserEdit"]);
    };
  }, []);

  async function registerEventBusListeners() {
    eventBus.removeMultiple(["openEditUser", "returnFromUserEdit"]);
    eventBus.on("openEditUser", () => {
      setFlag(true);
    });

    eventBus.on("returnFromUserEdit", () => {
      setFlag(false);
    });
  }

  return (
    <div className="content">
      {(loggedInUser.userType === "admin" ||
        loggedInUser.userType === "manager" ||
        loggedInUser.userType === "zoneManager") && (
        <Manager loggedInUser={loggedInUser} />
      )}
      {loggedInUser.userType === "installator" && !flag && (
        <InstallatorProfile loggedInUser={loggedInUser} />
      )}
      {loggedInUser.userType === "installator" && flag && (
        <EditInstallator loggedInUser={loggedInUser} />
      )}
    </div>
  );
}
