import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { crmReducer } from "./crmReducer";
import { productReducer } from "./productReducer";
import { comboReducer } from "./comboReducer";

const rootReducer = combineReducers({
  crmReducer,
  comboReducer,
  userReducer,
  productReducer,
});

export default rootReducer;
