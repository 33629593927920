import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { Card, CardBody, Button } from "reactstrap";
import ReactTable from "../components/ReactTable/ReactTable.js";
import { useDispatch } from "react-redux";
import { loadCrms, removeCrm } from "../store/actions/crmAction";
import { AdminEditModal } from "../cmps/edit/AdminEditModal";
import { eventBus } from "../services/eventBusService.js";
import { ClientCommentsModal } from "./ClientCommentsModal.jsx";
import { FilesModal } from "./files/FilesModal.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { genericCols } from "./dataColumns/GenericCols";
import { externalCols } from "./dataColumns/ExternalCols.js";
import { installatorCols } from "./dataColumns/InstallatorCols.js";
import { Oval } from "react-loading-icons";
import moment from "moment";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

const ClientsTable = ({ crms, loggedInUser }) => {
  const dispatch = useDispatch();
  const [alertState, setAlertState] = useState(null);
  const [moreInfoFlag, setMoreInfoFlag] = useState(false);
  const [commentFlag, setCommentFlag] = useState(false);
  const [fileFlag, setFileFlag] = useState(false);
  const [client, setClient] = useState({});
  const [filter, setFiter] = useState({});
  const [data, setData] = useState([]);
  const [viewType, setViewType] = useState("");
  const [unread, setUnread] = useState(false);
  const [uninvoiced] = useState(false);
  const [withFiles, setWithFiles] = useState(false);
  const [withoutFiles, setWithoutFiles] = useState(false);

  useEffect(() => {
    registerEventBusListeners();
    _getDataTable(crms);
    return () => {
      removeEventBusListeners();
    };
    // eslint-disable-next-line
  }, [crms]);

  async function removeEventBusListeners() {
    eventBus.removeMultiple(["rowClicked"]);
  }
  async function registerEventBusListeners() {
    removeEventBusListeners();
    eventBus.on("rowClicked", (obj) => {
      setClient(obj.client);
      setViewType("view");
      setMoreInfoFlag(true);
    });
  }

  const checkHasSomethingToRead = useCallback(
    (currentUserId, lastReads, lastComment) => {
      const currentUserReads =
        !!lastReads && lastReads.find((r) => r.userId === currentUserId);
      const lastCommentIsFromAnother =
        !!lastComment && lastComment.senderId !== currentUserId;
      const hasNoLastRead = currentUserReads === undefined;
      const lastReadIsOutdated =
        hasNoLastRead === true ||
        (hasNoLastRead === false &&
          !!lastComment &&
          lastComment.sentDate > currentUserReads.date);

      return lastCommentIsFromAnother && lastReadIsOutdated;
    },
    []
  );

  const buildActions = useCallback(
    (crm, withNotify, isZoneManagerOrExternal, hasFiles, isAdmin) => {
      return (
        <div className="table-actions">
          <Button
            onClick={() => {
              setClient(crm);
              setCommentFlag(true);
            }}
            color={`${withNotify ? "primary" : "info"}`}
            size="sm"
            className={"btn-icon btn-link like"}
          >
            <i className="tim-icons icon-chat-33" />
            {withNotify && <div className={"icons-tag"}>•</div>}
          </Button>
          <Button
            onClick={() => {
              setClient(crm);
              setFileFlag(true);
            }}
            color={hasFiles ? "primary" : "secondary"}
            size="sm"
            className={"btn-icon btn-link like"}
          >
            <i className="tim-icons icon-attach-87" />
          </Button>
          {(isZoneManagerOrExternal || isAdmin) && (
            <Button
              onClick={() => {
                setViewType(null);
                setClient(crm);
                setMoreInfoFlag(true);
              }}
              color="warning"
              size="sm"
              className={"btn-icon btn-link like"}
            >
              <i className="tim-icons icon-pencil" />
            </Button>
          )}
          {isAdmin && (
            <Button
              onClick={() => {
                alertEvent(
                  "Are You Sure You Want To Delete This Client ?",
                  crm._id
                );
              }}
              color="danger"
              size="sm"
              className={classNames("btn-icon btn-link like")}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>
          )}
        </div>
      );
    },
    // eslint-disable-next-line
    []
  );

  const _getDataTable = (arr) => {
    if (!arr) return;
    if (loggedInUser) {
      const { _id: currentUserId, userType } = loggedInUser;

      const isInstallatorOrManager =
        userType === "installator" || userType === "manager";
      const isZoneManagerOrExternal =
        userType === "zoneManager" || userType === "external";
      const isAdmin = userType === "admin";

      const data = arr.map((crm) => {
        const {
          _id: crmId,
          lastReads,
          lastComment,
          clientLastName,
          clientFirstName,
          clientPostalCode,
          pecDate,
          codeClient,
          firstVisit,
          secondVisit,
          status,
          paid,
          createdBy,
          installatorName,
          staMISSIONNEE,
          Nfacture,
          Nfacture2,
          products,
          files,
        } = crm;

        const hasSomethingToRead = checkHasSomethingToRead(
          currentUserId,
          lastReads,
          lastComment
        );

        const hasFiles = files && files.length > 0;
        console.log("products", products);
        let line = {
          id: crmId,
          hasSomethingToRead,
          client: crm,
          name: `${clientLastName} ${clientFirstName}`,
          codePostal: clientPostalCode,
          pecDate: pecDate ? moment(pecDate).format("DD/MM/YYYY") : "",
          codeClient,
          firstVisit: firstVisit ? moment(firstVisit).format("DD/MM/YYYY") : "",
          secondVisit: secondVisit
            ? moment(secondVisit).format("DD/MM/YYYY")
            : "",
          status,
          paid:
            paid === "NON" ? (
              <i className="tim-icons my-tim-icon icon-simple-remove" />
            ) : paid === "OUI" ? (
              <i className="tim-icons my-tim-icon2 icon-check-2" />
            ) : (
              "EN ATTENTE"
            ),
          products: products ? products.map((p) => p.productFamily) : "",
          createdBy,
          installatorName,
        };

        if (isAdmin) {
          line.Nfacture = Nfacture;
          line.Nfacture2 = Nfacture2;
          line.files = files;
          line.hasFiles = hasFiles;
        }

        if (isInstallatorOrManager) {
          line.codeClient = codeClient;
          line.productsModel = products
            ? products.map(
                (p) =>
                  `${p.productName} ${
                    p.productModel === "-" ? "" : p.productModel
                  }`
              )
            : "";
        } else if (isZoneManagerOrExternal) {
          line.createdBy = createdBy;
          line.Nfacture = Nfacture;
          line.staMISSIONNEE = staMISSIONNEE ? staMISSIONNEE.name : "";
        } else {
          line.codeClient = codeClient;
          line.createdBy = installatorName;
        }

        line.actions = buildActions(
          crm,
          hasSomethingToRead,
          isZoneManagerOrExternal,
          hasFiles,
          isAdmin
        );
        return line;
      });

      setData(data);
    }
  };

  const getExternal = () => {
    filter.workerType = "external";
    setFiter(filter);
    const filteredCrms = crms.filter((crm) => {
      return crm.workerType === "external";
    });
    _getDataTable(filteredCrms);
  };

  const getInternal = () => {
    filter.workerType = "servelite";
    setFiter(filter);
    const filteredCrms = crms.filter((crm) => {
      return crm.workerType === "internal";
    });
    _getDataTable(filteredCrms);
  };

  const getAll = async () => {
    filter.workerType = "";
    setFiter(filter);
    const allCrms = await dispatch(loadCrms());
    _getDataTable(allCrms);
  };

  const getAllClientCreate = async () => {
    filter.workerType = "internal";
    setFiter(filter);
    const filteredCrms = crms.filter((crm) => {
      return crm.status === "A TRAITER";
    });
    _getDataTable(filteredCrms);
  };

  const alertEvent = (title, id) => {
    setAlertState(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Etes vous sûrs de vouloir supprimer le client ?"
        onConfirm={() => successDelete(id)}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui, je supprime !"
        cancelBtnText="Annuler"
        showCancel
        btnSize=""
      ></SweetAlert>
    );
  };

  const successDelete = (id) => {
    setAlertState(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Supprimé !"
        onConfirm={() => {
          dispatch(removeCrm(id));
          setAlertState(null);
          eventBus.emit("clickedReturn");
          // window.location.assign("/#/dashboard");
          // window.location.reload();
        }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="success"
        btnSize=""
      ></SweetAlert>
    );
  };

  const getAllClientZManager = async () => {
    // alert('gkmfl')
    // const filter = {
    //     workerType: loggedInUser.zoneType
    // }
    // const test = await dispatch(loadCrms(filter));
    // console.log('jnk',test);
    // setCrms(test)
    _getDataTable(crms);
  };

  if (!loggedInUser)
    return <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;

  const { userType } = loggedInUser;

  const unreadData = data ? data.filter((f) => f.hasSomethingToRead) : [];
  const uninvoicedData = data
    ? data.filter((crm) => {
        return (
          !!crm.Nfacture === false &&
          !!crm.Nfacture2 === false &&
          !!crm.files &&
          crm.files.length > 0 &&
          crm.files.map((f) => f.fileType).includes("Rapport")
        );
      })
    : [];
  const withFilesData = data ? data.filter((crm) => crm.hasFiles) : [];
  const withoutFilesData = data ? data.filter((crm) => !crm.hasFiles) : [];

  const isAdmin = userType === "admin";
  const is360 = userType === "manager";
  const isInstallator = userType === "installator";
  const isExternal = userType === "zoneManager";

  return (
    <>
      {alertState}
      {moreInfoFlag && (
        <AdminEditModal
          client={client}
          viewType={viewType}
          isVisible={moreInfoFlag}
          onToggle={() => {
            setMoreInfoFlag((old) => !old);
          }}
        />
      )}
      {commentFlag && (
        <ClientCommentsModal
          client={client}
          isVisible={commentFlag}
          onToggle={() => {
            setCommentFlag((old) => !old);
          }}
        />
      )}
      {fileFlag && (
        <FilesModal
          client={client}
          isVisible={fileFlag}
          onToggle={() => setFileFlag((old) => !old)}
        />
      )}
      <div className="content">
        {(isAdmin || is360) && (
          <div className="flex filterBtnContainer">
            <Button color="success" onClick={getInternal}>
              SERVELITE
            </Button>
            <Button color="success" onClick={getExternal}>
              STA EXTERNES
            </Button>
            <Button
              color="success"
              onClick={getAllClientCreate}
              className="workerTypes-btn-container88"
            >
              A TRAITER
            </Button>
            <Button color="success" className="btn" onClick={getAll}>
              TOUS
            </Button>
            {isAdmin && (
              <>
                <Button
                  color={unread ? "success" : ""}
                  onClick={() => setUnread((old) => !old)}
                  className="workerTypes-btn-container88"
                >
                  MESSAGES NON LU
                </Button>
                {/* <Button
                  color={uninvoiced ? "success" : ""}
                  onClick={() => setUninvoiced((old) => !old)}
                  className="workerTypes-btn-container88"
                >
                  NON FACTURES
                </Button> */}
              </>
            )}
            {/* <button onClick={sendEmail}>send mail</button> */}
            <div className="pj-container">
              <label style={{ padding: 0, margin: 0 }}>
                Pièce(s) jointe(s)
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      colo="white"
                      checked={!!withFiles}
                      onChange={(e) => {
                        setWithoutFiles(false);
                        setWithFiles((old) => !old);
                      }}
                    />
                  }
                  label="Avec"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!withoutFiles}
                      onChange={(e) => {
                        setWithFiles(false);
                        setWithoutFiles((old) => !old);
                      }}
                    />
                  }
                  label="Sans"
                />
              </div>
            </div>
          </div>
        )}
        {isExternal && (
          <div className="flex filterBtnContainer2">
            <Button
              color="success"
              onClick={getAllClientCreate}
              className="workerTypes-btn-container88"
            >
              A TRAITER
            </Button>
            <Button
              color="success"
              onClick={getAllClientZManager}
              className="workerTypes-btn-container88 getZclient2"
            >
              TOUS
            </Button>
            <Button
              color={unread ? "success" : ""}
              onClick={() => setUnread((old) => !old)}
              className="workerTypes-btn-container88"
            >
              MESSAGES NON LU
            </Button>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      setWithoutFiles(false);
                      setWithFiles(true);
                    }}
                  />
                }
                label="Avec pièce(s) jointe(s)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    label="Sans pièce(s) jointe(s)"
                    onChange={(e) => {
                      setWithFiles(false);
                      setWithoutFiles(true);
                    }}
                  />
                }
                label="Sans pièce(s) jointe(s)"
              />
            </FormGroup>
          </div>
        )}
        {isInstallator && (
          <div className="flex filterBtnContainer">
            <Button
              color={unread ? "success" : ""}
              onClick={() => setUnread((old) => !old)}
            >
              MESSAGES NON LU
            </Button>
            <FormGroup style={{ marginLeft: "1rem" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      setWithoutFiles(false);
                      setWithFiles(true);
                    }}
                  />
                }
                label="Avec pièce(s) jointe(s)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    label="Sans pièce(s) jointe(s)"
                    onChange={(e) => {
                      setWithFiles(false);
                      setWithoutFiles(true);
                    }}
                  />
                }
                label="Sans pièce(s) jointe(s)"
              />
            </FormGroup>
          </div>
        )}

        {!!crms ? (
          <Card className="my-table">
            <CardBody>
              <ReactTable
                data={
                  unread
                    ? unreadData
                    : uninvoiced
                    ? uninvoicedData
                    : withFiles
                    ? withFilesData
                    : withoutFiles
                    ? withoutFilesData
                    : data
                }
                filterable
                resizable={false}
                columns={
                  loggedInUser.userType === "installator"
                    ? installatorCols
                    : loggedInUser.userType === "zoneManager" &&
                      loggedInUser.zoneType === "external"
                    ? externalCols
                    : genericCols
                }
                defaultPageSize={50}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight my-react-table"
              />
            </CardBody>
          </Card>
        ) : (
          <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />
        )}
      </div>
    </>
  );
};

export default ClientsTable;
