import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Sidebar from "../../components/Sidebar/Sidebar.js";
import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.js";

import routes from "../../routes/routes";
import regRoutes from "../../routes/regularUsersRoutes";
import managerRoutes from "../../routes/managerRoutes.js";

import logo from "../../assets/img/linkWell1.png";
import { Dashboard } from "../../pages/Dashboard";
import { UserProfile } from "../../cmps/UserProfile";
import { eventBus } from "../../services/eventBusService.js";
import { AdminEdit } from "../../cmps/edit/AdminEdit.jsx";
import { TermOfUse } from "../../cmps/TermOfUse";
import { Oval } from 'react-loading-icons';

const Admin = (props) => {
  const [activeColor, setActiveColor] = useState("blue");
  const [sidebarMini, setSidebarMini] = useState(false);
  const [opacity] = useState(0);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const mainPanelRef = useRef(null);
  const [profileFlag, setProfileFlag] = useState(false);
  const [dashFlag, setDahFlag] = useState(true);
  const [editFlag, setEditFlag] = useState(false);
  const [currClient, setCurrClient] = useState({});
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [lmFlag, setLmFlag] = useState();
  const location = useLocation();
  //const [tableStyling, setTableStyling] = useState('')

  useEffect(() => {
    //console.log('gkjnbkgnbkbnkg',props.notis);
    if(loggedInUser) {
      setLmFlag(loggedInUser.legalMention)
    }
    registerEventBusListeners();
    document.body.classList.add("sidebar-mini");
    document.documentElement.classList.remove("nav-open");
    return () => {
      removeEventBusListeners();
    };
    // eslint-disable-next-line
  }, [loggedInUser]);

  useEffect(() => {
    document.documentElement.classList.remove("nav-open");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);

  async function removeEventBusListeners() {
    eventBus.removeMultiple(["clickedReturn", "editClicked"]);
  }
  async function registerEventBusListeners() {
    removeEventBusListeners();
    eventBus.on("clickedReturn", () => {
      setProfileFlag(false);
      setEditFlag(false);
      setDahFlag(true);
    });

    eventBus.on("editClicked", (client) => {
      setCurrClient(client);
      setDahFlag(false);
      setEditFlag(true);
    });
  }


  const getActiveRoute = (routes) => {
    //console.log(routes);
    let activeRoute = routes.name;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleMiniClick = () => {
    // let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
      // notifyMessage += "deactivated...";
    } else {
      setSidebarMini(false);
      // notifyMessage += "activated...";
    }
    // let options = {
    //   place: "tr",
    //   message: notifyMessage,
    //   type: "primary",
    //   icon: "tim-icons icon-bell-55",
    //   autoDismiss: 7,
    // };
    //notificationAlertRef.current.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };

  const selectUserProfile = () => {
    setDahFlag(false);
    setProfileFlag(true);
  };

  

  const agreeToTerms = ()=> {
    setLmFlag(true);
  }

  const renderSidebar = ()=> {
    if(loggedInUser.userType==='installator'&& !loggedInUser.legalMention) return;
    else {
      return (
         <Sidebar
        {...props}
        routes={
          loggedInUser
            ? loggedInUser.userType === "installator" ||
              loggedInUser.userType === "zoneManager"
              ? regRoutes
              : loggedInUser.userType === "admin"
              ? routes
              : managerRoutes
            : routes
        }
        activeColor={activeColor}
        logo={{
          outterLink: "https://www.servelite.fr/professionnels/nos_services",
          text: "Servelite",
          imgSrc: logo,
        }}
        closeSidebar={closeSidebar}
      />
      )
    }
  }

  if (!loggedInUser) return <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />;
  return (
    <div className="wrapper">
      {!lmFlag&&loggedInUser.userType==='installator'&&<TermOfUse loggedInUser={loggedInUser} agreeToTerms={agreeToTerms} />}
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      {renderSidebar()}
      <div className="main-panel" ref={mainPanelRef} data={activeColor}>
        <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
          selectUserProfile={selectUserProfile}
          loggedInUser={loggedInUser}
        />

        <div className="dashboardAdmin">
          {dashFlag && <Dashboard />}
          {profileFlag && <UserProfile />}
          {editFlag && <AdminEdit client={currClient} />}
        </div>
      </div>
      {loggedInUser.userType !== "installator" && (
        <FixedPlugin
          activeColor={activeColor}
          sidebarMini={sidebarMini}
          handleActiveClick={handleActiveClick}
          handleMiniClick={handleMiniClick}
        />
      )}
    </div>
  );
};

export default Admin;
