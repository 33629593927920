import httpService from './httpService';


export const productService = {
    save,
    query,
    getById,
    remove
  }
  
  function query(filterBy = {}) {
    let queryStr = '?';
    for (const key in filterBy) {
      queryStr += `${key}=${filterBy[key]}&`;
    }


    return httpService.get(`product${queryStr || ''}`);
  }
  

  
  function getById(productId) {
    //console.log(productId);
    return httpService.get(`product/${productId}`)
  }
  
  function remove(productId) {
    return httpService.delete(`product/${productId}`)
  }
  
  function save(product) {
    //console.log(product);
    if (product._id) {
      return httpService.put(`product/${product._id}`, product)
    } else {
      return httpService.post(`product`, product);
    }
  }

  