import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Button, Input } from "reactstrap";
import { mailService } from "../services/mailService";
import { saveCrm } from "../store/actions/crmAction";
import { useDispatch } from "react-redux";

export function CommentLine({ crm, currentUser, onClose }) {
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const [msgInput, setMsgInput] = useState("");
  const { _id, fullName } = currentUser;

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line
  }, []);

  const msgInputChange = (ev) => {
    setMsgInput(ev.target.value);
  };

  const onSubmitMsgInput = () => {
    if (!!crm && !!msgInput) {
      if (!crm.comments) crm.comments = [];
      const sentDate = Date.now();
      const newMsgObj = {
        senderId: _id,
        name: fullName,
        date: Date.now(),
        msg: msgInput,
      };

      crm.comments.push(newMsgObj);
      crm.lastComment = { senderId: _id, sentDate };
      dispatch(saveCrm(crm));
      setMsgInput("");
      scrollToBottom();

      if (currentUser.userType !== "installator") {
        const mailObj = {
          to: crm.installator,
          crm: crm,
        };
        mailService.sendEmail(mailObj);
      } else {
        const mailObj = {
          to: "managers",
          crm: crm,
        };
        mailService.sendEmail(mailObj);
      }
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="comment-line-container">
      <div className="comment-modal-header">
        {crm && <h4>{`${crm.clientLastName} ${crm.clientFirstName}`}</h4>}
        <i
          className="tim-icons icon-simple-remove close-icon"
          onClick={onClose}
        />
      </div>
      <div className="msgs-container">
        {crm.comments && crm.comments.length > 0 ? (
          crm.comments.map((comment, idx) => {
            const isOwner = comment.senderId === _id;
            const previousComment = idx - 1 > -1 && crm.comments[idx - 1];
            const previousSender =
              previousComment &&
              !!previousComment.senderId &&
              previousComment.senderId;
            const isSameSender =
              previousSender && previousSender === comment.senderId;
            const alignment = isOwner ? "right" : "left";
            return (
              <div
                key={idx}
                className={`flex-col comment-container ${alignment} ${
                  isSameSender ? "nogap" : ""
                }`}
              >
                {!isSameSender && (
                  <div className={`message-header ${alignment}`}>
                    <span className="message-sender">{comment.name}</span>
                  </div>
                )}
                <div className={`message ${alignment}`}>
                  <p className={`message-text ${alignment}`}>{comment.msg}</p>
                  <div className={`message-time ${alignment}`}>
                    {moment(comment.date).format("DD/MM/YYYY à HH:mm")}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className="no-comments-text">Aucun message ...</p>
        )}
        <div ref={messagesEndRef} style={{ height: 40 }} />
      </div>
      <form onSubmit={onSubmitMsgInput} className="send-comment-container">
        <Input
          className="comment-input"
          type="text"
          value={msgInput}
          placeholder="Votre message"
          onChange={msgInputChange}
        />
        <Button color="primary" onClick={onSubmitMsgInput}>
          Envoyer
        </Button>
      </form>
    </div>
  );
}
