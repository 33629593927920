import { userService } from "../../services/userService";

export function setFilter(filter) {
  return (dispatch) => {
    dispatch({ type: "SET_FILTER", filter });
  };
}

export function clearFilter() {
  return (dispatch) => {
    dispatch({ type: "CLEAR_FILTER" });
  };
}

export function loadUsers(filterBy) {
  return async (dispatch) => {
    const users = await userService.query(filterBy);
    dispatch({ type: "SET_USERS", users });
  };
}

export function removeUser(userId) {
  return async (dispatch) => {
    await userService.remove(userId);
    dispatch({ type: "REMOVE_USER", userId });
  };
}

export function saveUser(user) {
  return async (dispatch) => {
    const actionType = user._id ? "UPDATE_USER" : "ADD_USER";
    user = await userService.save(user);
    dispatch({ type: actionType, user });
  };
}

export function login(userCreds) {
  return async (dispatch) => {
    try {
      const user = await userService.login(userCreds);
      if (!!user) {
        dispatch({ type: "SET_USER", user });
        return { ok: true };
      }
    } catch (error) {
      console.log("error", error);
      return { ok: false, error: error.message };
    }
  };
}

export function signup(userCreds) {
  return async (dispatch) => {
    const user = await userService.signup(userCreds);
    console.log(user);
    if (user === "This Email Is Alreay Exists") return user;
    dispatch({ type: "SET_USER", user });
  };
}

export function logout() {
  return async (dispatch) => {
    dispatch({ type: "SET_USER", user: null });
    await userService.logout();
  };
}
