import { crmService } from "../../services/crmService";

export function loadCrms(filterBy) {
  return async (dispatch) => {
    const crms = await crmService.query(filterBy);
    dispatch({ type: "SET_CRMS", crms });
    return crms;
  };
}

export function removeCrm(crmId) {
  return async (dispatch) => {
    await crmService.remove(crmId);
    dispatch({ type: "REMOVE_CRM", crmId });
  };
}

export function saveCrm(crm) {
  return async (dispatch) => {
    const actionType = crm._id ? "UPDATE_CRM" : "ADD_CRM";
    crm = await crmService.save(crm);
    if (crm.msg) return crm.msg;
    else dispatch({ type: actionType, crm });
  };
}

export function setEditCrm(crmIdxEdited) {
  return async (dispatch) => {
    dispatch({ type: "SET_CRMIDXEDITED", crmIdxEdited });
  };
}

export function clearCrms() {
  return async (dispatch) => {
    dispatch({ type: "CLEAR_CRMS" });
  };
}
