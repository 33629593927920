import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Row,
  Col,
} from "reactstrap";
import { eventBus } from "../../services/eventBusService";
import ImageUpload from "../../components/CustomUpload/ImageUpload.js";
import { fileService } from "../../services/fileService";
import { saveUser } from "../../store/actions/userAction";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

export function Manager({ loggedInUser }) {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [flag, setFlag] = useState(false);
  const [alertState, setAlertState] = useState(null);

  const alertEvent = (title) => {
    setAlertState(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          setAlertState(null);
          eventBus.emit("clickedReturn");
          //window.location.reload();
        }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      ></SweetAlert>
    );
  };

  const onClickReturn = () => {
    eventBus.emit("clickedReturn");
  };

  const onHandleImageChange = (fileData) => {
    fileData ? setFile(fileData) : setFile(null);
  };

  const onSubmitProfile = () => {
    submitImage();
    loggedInUser.logo = file ? file.name : loggedInUser.logo;
    dispatch(saveUser(loggedInUser));
    alertEvent("Photo de profil modifiée !");
  };

  const submitImage = async () => {
    if (file) {
      const data = new FormData();
      data.append("file", file);
      await fileService.save(data);
    } else return;
  };

  return (
    <>
      {alertState}
      <Row className="managerProfile-container">
        <Col md="4">
          <Card className="card-user my-card-user">
            <CardBody>
              <CardText />
              <div className="author">
                <div className="block block-one" />
                <div className="block block-two" />
                <div className="block block-three" />
                <div className="block block-four" />

                {!flag && (
                  <img
                    alt="..."
                    className="avatar"
                    src={`https://serveilte.fra1.digitaloceanspaces.com/${loggedInUser.logo}`}
                  />
                )}
                {flag && (
                  <ImageUpload
                    addBtnColor="default"
                    changeBtnColor="default"
                    onHandleImageChange={onHandleImageChange}
                    loggedInUser={loggedInUser}
                  />
                )}
                <h5 className="title">{loggedInUser.fullName}</h5>

                <p className="description">{loggedInUser.userType}</p>
              </div>
              <div className="card-description">
                <h4>{loggedInUser.email}</h4>
                {loggedInUser.userType === "zoneManager" && (
                  <h4>{loggedInUser.zoneType}</h4>
                )}
              </div>
            </CardBody>
            <CardFooter className="fo-btn flex">
              <Button
                onClick={onClickReturn}
                className="btn-fill ret-btn"
                color="primary"
                type="submit"
              >
                Retour
              </Button>
              {!flag && (
                <Button
                  onClick={() => {
                    setFlag(!flag);
                  }}
                  className="btn-fill"
                  color="info"
                  type="button"
                >
                  Modifier ma photo
                </Button>
              )}
              {flag && (
                <Button
                  onClick={onSubmitProfile}
                  className="btn-fill my-save-btn"
                  color="info"
                  type="button"
                >
                  Sauvegarder
                </Button>
              )}
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
}
