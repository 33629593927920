import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Row,
  Col,
} from "reactstrap";
import { eventBus } from "../../services/eventBusService";

export function InstallatorProfile({ loggedInUser }) {
  const onClickReturn = () => {
    eventBus.emit("clickedReturn");
  };

  const onClickEdit = () => {
    eventBus.emit("openEditUser");
  };

  return (
    <Row className="managerProfile-container">
      <Col md="4">
        <Card className="card-user my-card-user">
          <CardBody>
            <CardText />
            <div className="author">
              <div className="block block-one" />
              <div className="block block-two" />
              <div className="block block-three" />
              <div className="block block-four" />
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img
                  alt="avatar"
                  className="avatar"
                  src={
                    loggedInUser.logo
                      ? `https://serveilte.fra1.digitaloceanspaces.com/${loggedInUser.logo}`
                      : require("../../assets/img/mike.jpg").default
                  }
                />
                <h5 className="title">{loggedInUser.fullName}</h5>
              </a>
              <p className="description">{loggedInUser.companyName}</p>
              <p className="description">{loggedInUser.siren}</p>
            </div>
            <div className="card-description">
              <h4>{loggedInUser.email}</h4>
              <h4>{loggedInUser.adress}</h4>

              <h4>{loggedInUser.telephone}</h4>
              <h4>{loggedInUser.mobile}</h4>
            </div>
          </CardBody>
          <CardFooter className="profile-footer flex">
            <Button
              onClick={onClickReturn}
              className="btn-fill ret-btn5"
              color="primary"
            >
              Retour
            </Button>
            <Button
              onClick={onClickEdit}
              className="btn-fill edit-btn5"
              color="info"
            >
              Modifier
            </Button>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
}
