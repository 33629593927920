export const utilsService = {
  verifyLength,
  verifyPhoneNum,
  verifyEmail,
  verifyNumber,
  checkZoneArea,
  createOptions,
  makeId,
};

// function that returns true if value is email, false otherwise
function verifyEmail(value) {
  var emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}
// function that verifies if a string has a given length or not
function verifyLength(value, length = 6) {
  if (value.length >= length) {
    return true;
  }
  return false;
}

// function that verifies if phone number is correct
function verifyPhoneNum(value) {
  var phoneNumRegex = /^(\+33|0033|0)(1|2|3|4|5|9|6|7|9)[0-9]{8}$/;
  if (phoneNumRegex.test(value)) {
    return true;
  }
  return false;
}

// function that verifies if value contains only numbers
function verifyNumber(value) {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
}

const zones = [
  "12",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "23",
  "24",
  "36",
  "37",
  "41",
  "46",
  "48",
  "50",
  "58",
  "61",
  "63",
  "65",
  "66",
  "72",
  "79",
  "86",
  "87",
];

// function that identifies whether the selected area is internal/external
function checkZoneArea(zone) {
  const zoneArea = zone.slice(0, 2);
  if (zones.includes(zoneArea)) {
    return false;
  } else return true;
}

//function that genrates options for react-select
function createOptions(arr) {
  var options = [];
  for (var i = 0; i < arr.length; i++) {
    const obj = {
      value: arr[i],
      label: arr[i].STA,
    };
    options.push(obj);
  }
  return options;
}

//function that genrates IDs
function makeId(length = 16) {
  var txt = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    txt += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return txt;
}
