import httpService from "./httpService";

export const notificationService = {
  save,
  query,
  getById,
  remove,
};

function query(filterBy = {}) {
  let queryStr = "?";
  for (const key in filterBy) {
    queryStr += `${key}=${filterBy[key]}&`;
  }
  return httpService.get(`notification${queryStr || ""}`);
}

function getById(notificationId) {
  //console.log(notificationId);
  return httpService.get(`notification/${notificationId}`);
}

function remove(notificationId) {
  return httpService.delete(`notification/${notificationId}`);
}

function save(notification) {
  //console.log(notification);
  if (notification._id) {
    return httpService.put(`notification/${notification._id}`, notification);
  } else {
    return httpService.post(`notification`, notification);
  }
}
