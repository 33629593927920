function toHumanReadable(size) {
  if (!size) return "N/A";
  if (size === 0) return "0 Ko";
  const kb = (size / 1000).toFixed(2);
  if (kb < 1000) return `${kb} Ko`;
  if (kb >= 1000 && kb < 1000000) return `${(kb / 1000).toFixed(2)} Mo`;
  if (kb >= 1000000) return `${(kb / 1000000).toFixed(2)} Go`;
}

function getFileExtensionFromName(filename) {
  if (!filename) return "";
  const fileExt = filename.split(".").pop();
  return fileExt;
}

module.exports = {
  getFileExtensionFromName,
  toHumanReadable,
};
