import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCrms } from "../store/actions/crmAction";
import ClientsTable from "../cmps/ClientsTable";
import { eventBus, EVENT_BUS_EVENTS } from "../services/eventBusService";

export function Dashboard() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const crms = useSelector((state) => state.crmReducer.crms);

  useEffect(() => {
    async function init() {
      await getCrms();
      registerEventBusListeners();
    }
    init();
    return () => {
      removeEventBusListeners();
    };
    //  eslint-disable-next-line
  }, [loggedInUser]);

  const getCrms = async () => {
    if (loggedInUser) {
      let filter = {};
      if (loggedInUser.userType === "zoneManager") {
        filter = {
          workerType: loggedInUser.zoneType,
        };
      } else if (loggedInUser.userType === "installator") {
        filter = {
          installator: loggedInUser.companyName,
          _id: loggedInUser._id,
        };
      }
      await dispatch(loadCrms(filter));
    }
  };

  function removeEventBusListeners() {
    eventBus.removeMultiple([EVENT_BUS_EVENTS.readMessages]);
  }
  function registerEventBusListeners() {
    removeEventBusListeners();
    eventBus.on(EVENT_BUS_EVENTS.readMessages, () => {
      getCrms();
    });
  }

  return <ClientsTable crms={crms} loggedInUser={loggedInUser} />;
}
