import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, CustomInput, FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fileService } from "../../services/fileService";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { utilsService } from "../../services/utils";
import { mailService } from "../../services/mailService";
import { toHumanReadable } from "../../helpers/files";
import { saveCrm } from "../../store/actions/crmAction";

export function DragFile({ crm, onClose }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [checkFlag, setCheckFlag] = useState(false);
  const [alertState2, setAlertState2] = useState(null);
  const [uploading, setUploading] = useState(false);

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} -{toHumanReadable(file.size)} bytes
    </li>
  ));

  const onSendFile = async () => {
    const type = checkFlag ? "Factures" : "Rapport";
    setUploading(true);
    try {
      for (let i = 0; i < acceptedFiles.length; i++) {
        if (!crm.files) crm.files = [];
        const data = new FormData();
        data.append("file", acceptedFiles[i]);
        const fileId = await fileService.save(data);

        const fileObj = {
          _id: utilsService.makeId(),
          uploaderName: loggedInUser.fullName,
          name: acceptedFiles[i].name,
          size: acceptedFiles[i].size,
          fileType: type,
          date: Date.now(),
          docId: fileId,
        };
        crm.files.push(fileObj);
      }
      if (type === "Factures") {
        mailService.sendEmail(crm);
      }
      dispatch(saveCrm(crm));
      alertEvent2("Vos fichiers ont bien été envoyés");
    } catch (error) {
      console.error(error.message);
    } finally {
      setUploading(false);
    }
  };

  const alertEvent2 = (title) => {
    setAlertState2(
      <ReactBSAlert
        success
        style={{ display: "inline", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          setAlertState2(null);
          onClose();
        }}
        confirmBtnBsStyle="primary"
        confirmBtnText="J'ai compris!"
      >
        {title}
      </ReactBSAlert>
    );
  };

  const onCloseDnd = () => {
    onClose();
  };

  return (
    <>
      {alertState2}
      <div className="flex-col dragFile-container">
        <section className="dnd-container">
          <div {...getRootProps({ className: "dropzone flex-col" })}>
            <input {...getInputProps()} />
            <p>Drag and drop vos fichiers ici</p>
          </div>
          <aside className="files-header">
            <ul className="dnd-ul">{files}</ul>
          </aside>
        </section>
        <div className="flex-col-between btn-dnd-container">
          <div className="flex-col">
            <FormGroup className="flex">
              <label className="switch-label">Rapport d'intervention</label>
              <CustomInput
                label="Factures"
                type="switch"
                name="managerType"
                id="switch-1"
                onChange={(ev) => {
                  setCheckFlag(!checkFlag);
                }}
              />
            </FormGroup>
          </div>
          <div className="flex-between">
            <Button
              color="danger"
              className="close-dnd-container"
              onClick={onCloseDnd}
            >
              Fermer
            </Button>
            <Button
              className=""
              onClick={onSendFile}
              disabled={uploading || acceptedFiles.length < 1}
            >
              Envoyer
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
