import React from "react";
import { Switch, Route } from "react-router-dom";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { CreateClient } from "./pages/CreateClient";
import Admin from "./layouts/Admin/Admin";
import { ExportTable } from "./pages/ExportTable";
import { Links } from "./pages/Links";
import { ProtectedRoutes } from "./cmps/ProtectedRoutes";
import { LegalMention } from "./pages/LegalMention";
import { useDispatch } from "react-redux";
import { loadCombos } from "./store/actions/comboAction";

export function App() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(loadCombos());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="app-wrapper">
      <Switch>
        <ProtectedRoutes component={Links} exact path="/dashboard/links" />
        <ProtectedRoutes
          component={ExportTable}
          exact
          path="/dashboard/exportTable"
        />
        <ProtectedRoutes
          component={CreateClient}
          exact
          path="/dashboard/createClient"
        />
        <ProtectedRoutes
          component={LegalMention}
          exact
          path="/dashboard/LegalMention"
        />
        <ProtectedRoutes component={Admin} exact path="/dashboard" />
        <ProtectedRoutes component={Signup} exact path="/signup" />
        <Route component={Login} exact path="/" />
      </Switch>
    </div>
  );
}
