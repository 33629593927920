import React, { useState, useEffect } from "react";
import {
  Button,
  CustomInput,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { saveCrm } from "../../store/actions/crmAction";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { staService } from "../../services/staService";
import { utilsService } from "../../services/utils";
import { mailService } from "../../services/mailService";
import { localStorageService } from "../../services/localStorageService";
import { t } from "../../constants/text";
import { installatorService } from "../../services/installatorService";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

const STATUS = {
  TODO: "A TRAITER",
  PEC_IN_PROGRESS: "PEC EN COURS",
  FIRST_VISIT: "1E VISITE FIXEE",
  REPORT_RECEIVED: "RAPPORT RECU",
  NOT_CONFORM: "NON CONFORME",
  CONFORM: "INSTALLATION OK",
};

export function AdminEdit({ client, viewType, onToggle }) {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const isAdmin = loggedInUser && loggedInUser.userType === "admin";
  const isInstallator = loggedInUser && loggedInUser.userType === "installator";

  const [alertState, setAlertState] = useState(null);
  const [alertState2, setAlertState2] = useState(null);
  const [workFlag, setWorkFlag] = useState(client.subTreated);
  const [stas, setStas] = useState([]);

  const [editAddress, setEditAddress] = useState(false);
  const [clientAddress, setClientAddress] = useState(client.clientAdress);
  const [clientAddressState, setClientAddressState] = useState("");
  const [clientPostalCode, setClientPostalCode] = useState(
    client.clientPostalCode
  );
  const [codeClient, setCodeClient] = useState(client.codeClient);
  const [clientTel, setClientTel] = useState(client.clientTel);
  const [clientMobile, setClientMobile] = useState(client.clientMobile);
  const [clientEmail, setClientEmail] = useState(client.clientEmail);
  const [installationDate, setInstallationDate] = useState(
    client.installationDate
  );
  const [freeTxt, setFreeTxt] = useState(client.freeTxt);
  const [pecDate, setPecDate] = useState(client.pecDate);
  const [conformite, setConformite] = useState(client.conformite);
  const [firstVisit, setFirstVisit] = useState(client.firstVisit);
  const [secondVisit, setSecondVisit] = useState(client.secondVisit);
  const [Nfacture, setNfacture] = useState(client.Nfacture);
  const [DateDip] = useState(client.DateDip);
  const [dip2eme] = useState(client.dip2eme);
  const [staMISSIONNEE, setStaMISSIONNEE] = useState(client.staMISSIONNEE);
  const [ndip, setNdip] = useState(client.ndip);
  const [Nfacture2, setNfacture2] = useState(client.Nfacture2);
  const [isNPR, setIsNPR] = useState(client.isNPR);
  const [isFirstVisit, setIsFirstVisit] = useState(client.isFirstVisit);
  const [internalComments, setInternalComments] = useState(
    client.internalComments
  );
  const [societe, setSociete] = useState(client.societe);
  const [installator, setInstallator] = useState(client.installator);
  const [installatorName, setInstallatorName] = useState(
    client.installatorName
  );
  const [RAPPORT, setRAPPORT] = useState(client.RAPPORT);
  const [status, setStatus] = useState(client.status);

  const [theme, setTheme] = useState(null);
  const [installatorOptions, setInstallatorOptions] = useState([]);

  const options = [
    { value: "A TRAITER", label: "A TRAITER" },
    { value: "PEC EN COURS", label: "PEC EN COURS" },
    { value: "1E VISITE FIXEE", label: "1E VISITE FIXEE" },
    { value: "RAPPORT RECU", label: "RAPPORT RECU" },
    { value: "INSTALLATION OK", label: "INSTALLATION OK" },
    { value: "NON CONFORME", label: "NON CONFORME" },
    { value: "ANNULÉ", label: "ANNULÉ" },
  ];

  const booleanOptions = [
    { value: "OUI", label: "OUI" },
    { value: "NON", label: "NON" },
  ];

  const clientObj = {
    _id: client._id,
    clientFirstName: client.clientFirstName,
    clientLastName: client.clientLastName,
    clientAdress: clientAddress,
    clientPostalCode: clientPostalCode,
    clientTel: clientTel,
    clientMobile: clientMobile,
    clientEmail: clientEmail,
    installationDate: installationDate ? new Date(installationDate) : null,
    status: status,
    freeTxt: freeTxt,
    societe: societe,
    products: client.products,
    totalPrice: client.totalPrice,
    createdBy: client.createdBy,
    createdByEmail: client.createdByEmail,
    createdAt: client.createdAt,
    pecDate: pecDate ? new Date(pecDate) : null,
    ndip: ndip,
    conformite: conformite,
    firstVisit: firstVisit ? new Date(firstVisit) : null,
    secondVisit: secondVisit ? new Date(secondVisit) : null,
    Nfacture: Nfacture,
    Nfacture2: Nfacture2,
    paid: client.paid,
    comments: client.comments,
    files: client.files,
    subTreated: workFlag,
    RAPPORT: RAPPORT,
    staMISSIONNEE: staMISSIONNEE,
    DateDip: DateDip ? new Date(DateDip) : null,
    dip2eme: dip2eme,
    codeClient: codeClient,
    workerType: workFlag ? "external" : "internal",
    isNPR: isNPR,
    isFirstVisit: isFirstVisit,
    internalComments: internalComments,
    installator: installator,
    installatorName: installatorName,
    //installatorEmail : installatorEmail
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      window.scrollTo(0, 0);
      const userPref = localStorageService.loadFromStorage("theme");
      if (userPref) setTheme(userPref.theme);
      getStas();
      if (loggedInUser.userType === "admin") getInstallators();
    }
    return () => {
      mount = false;
    };
    // eslint-disable-next-line
  }, []);

  const getInstallators = async () => {
    const usersData = await installatorService.query();
    const instOptions = usersData.map((user) => {
      return {
        value: user.companyName,
        label: user.companyName,
      };
    });
    setInstallatorOptions(instOptions);
  };

  const getStas = async () => {
    const data = await staService.query();
    data.sort(function (a, b) {
      var nameA = a.STA.toUpperCase(); // ignore upper and lowercase
      var nameB = b.STA.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    setStas(data);
    //console.log(data);
  };

  const alertEvent2 = (title) => {
    setAlertState2(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          setAlertState2(null);
        }}
        onCancel={() => setAlertState2(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      ></SweetAlert>
    );
  };

  const alertEvent = (title) => {
    setAlertState(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          setAlertState(null);
          onToggle();
        }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      ></SweetAlert>
    );
  };

  const onSave = () => {
    if (ndip && ndip.length > 10) {
      alertEvent2("Le N° DIP doit être de 10 chiffres maximum");
      return;
    } else if (Nfacture && (Nfacture.length > 7 || Nfacture2.length > 7)) {
      alertEvent2("Le Nfacture doit être de 7 chiffres maximum");
    } else {
      checkClientStatus();
      dispatch(saveCrm(clientObj));
      alertEvent("Modification Sauvegardée");
    }
  };

  const handleSwitch = (ev) => {
    setWorkFlag(!workFlag);
    //setWorkerType()
    //clientObj.subTreated = !clientObj.subTreated
  };

  const checkClientStatus = () => {
    if (clientObj.isNPR !== client.isNPR) {
      if (clientObj.installatorName) mailService.sendEmail(clientObj);
    }

    const { status } = client;
    const firstConditionChanged =
      client.pecDate !== clientObj.pecDate ||
      client.codeClient !== clientObj.codeClient ||
      client.ndip !== clientObj.ndip;
    const secondConditionChanged =
      moment(client.firstVisit).format("DD/MM/YYYY") !==
      moment(clientObj.firstVisit).format("DD/MM/YYYY");
    const thirdConditionChanged = client.RAPPORT !== clientObj.RAPPORT;
    const fourthConditionChanged = client.conformite !== clientObj.conformite;

    // STEP 1
    if (status === STATUS.TODO && firstConditionChanged) {
      if (!!clientObj.pecDate && (!!clientObj.codeClient || !!clientObj.ndip))
        clientObj.status = STATUS.PEC_IN_PROGRESS;
    }

    // STEP 2
    if (status === STATUS.PEC_IN_PROGRESS && secondConditionChanged) {
      if (!!clientObj.firstVisit) {
        clientObj.status = STATUS.FIRST_VISIT;
        if (clientObj.installatorName) mailService.sendEmail(clientObj);
      }
    }

    // STEP 3
    if (status === STATUS.FIRST_VISIT && thirdConditionChanged) {
      if (!!clientObj.RAPPORT && clientObj.RAPPORT === "OUI") {
        clientObj.status = STATUS.REPORT_RECEIVED;
        if (clientObj.installatorName) mailService.sendEmail(clientObj);
      }
    }

    // STEP 4
    if (
      (status === STATUS.REPORT_RECEIVED || status === STATUS.NOT_CONFORM) &&
      fourthConditionChanged
    ) {
      if (!!clientObj.conformite && conformite === "OUI")
        clientObj.status = STATUS.CONFORM;
      if (clientObj.installatorName) mailService.sendEmail(clientObj);
      if (!!clientObj.conformite && conformite === "NON")
        clientObj.status = STATUS.NOT_CONFORM;
    }

    if (status === STATUS.NOT_CONFORM) {
      if (
        clientObj.isFirstVisit !== client.isFirstVisit &&
        clientObj.isFirstVisit === true
      ) {
        mailService.sendEmail(clientObj);
      }
    }
    if (status === STATUS.CONFORM) {
      // TODO ?
    }
  };

  const handleAddressChange = async (value) => {
    if (
      value.value.types[0] === "locality" ||
      value.value.types[0] === "postal_code"
      // value.value.types[0] === "geocode" ||
      // value.value.types[0] === "route"
      // value.value.types[0] !== "street_address" &&
      // value.value.types[0] !== "bar" &&
      // value.value.types[0] !== "neighborhood" &&
      // value.value.types[0] !== "premise"
      // value.value.types[0] !== "point_of_interest" &&
      // value.value.types[0] !== 'car_dealer' &&
      // value.value.types[0] !== 'store' &&
      // value.value.types[0] !== 'establishment'
    ) {
      setClientAddressState("has-danger");
    } else {
      try {
        const data = await geocodeByPlaceId(value.value.place_id);
        console.log("data", data);

        setClientAddress(value.label);
        const addressParts = data[0].address_components;
        const zipCodePart = addressParts.find(
          (p) => p.types[0] === "postal_code"
        );
        const zipCode = zipCodePart?.long_name || "";
        setClientPostalCode(zipCode);
        setClientAddressState("has-success");
        setEditAddress(false);
      } catch (error) {
        console.log("error geocoding:", error);
      }
    }
  };

  const { products, totalPrice } = client;

  return (
    <div className="admin-edit-modal" onClick={(e) => e.stopPropagation()}>
      {alertState}
      {alertState2}
      <i
        className="tim-icons icon-simple-remove close-icon"
        onClick={onToggle}
      />
      <Form>
        <div className="section">
          <h1>Informations du client</h1>
          <Row>
            <Col className="pl-md-3" md="3">
              <FormGroup>
                <label>CODE CLIENT</label>
                <Input
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setCodeClient(ev.target.value);
                  }}
                  defaultValue={codeClient}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="pr-md-1" md="3">
              <FormGroup>
                <label>PRENOM</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  value={client.clientFirstName}
                  disabled
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="pr-md-1" md="3">
              <FormGroup>
                <label>NOM</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  value={client.clientLastName}
                  disabled
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pl-md-3" md="6">
              <FormGroup className="edit-address-group">
                <label>
                  ADRESSE{" "}
                  {viewType !== "view" && (
                    <i
                      className={`tim-icons ${
                        editAddress ? "icon-simple-remove" : "icon-pencil"
                      }`}
                      onClick={() => setEditAddress((old) => !old)}
                    />
                  )}
                </label>
                {editAddress ? (
                  <>
                    <div className={theme ? "myLightMode" : ""}>
                      <GooglePlacesAutocomplete
                        autocompletionRequest={{
                          componentRestrictions: { country: "fr" },
                        }}
                        autoFillOnNotFound={true}
                        listEmptyComponent={() => <div>{t.NoOptions}</div>}
                        apiOptions={{ language: "fr", region: "fr" }}
                        selectProps={{
                          noOptionsMessage: () => t.NoOptions,
                          placeholder: "....",
                          value: clientAddress,
                          defaultValue: clientAddress,
                          onChange: handleAddressChange,
                        }}
                      />
                    </div>
                    {clientAddressState === "has-danger" ? (
                      <label className="error">
                        Merci de renseigner une adresse valide.
                      </label>
                    ) : null}
                  </>
                ) : (
                  <Input
                    className={theme ? "myDarkInput" : ""}
                    disabled={true}
                    onChange={(ev) => {
                      setClientAddress(ev.target.value);
                    }}
                    defaultValue={clientAddress}
                    type="text"
                  />
                )}
              </FormGroup>
            </Col>
            <Col className="pr-md-1" sm="2">
              <FormGroup>
                <label>POSTAL CODE</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  value={clientPostalCode}
                  disabled
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col sm="1">
              <FormGroup>
                <label>NRP</label>
                <CustomInput
                  disabled={
                    !viewType ||
                    loggedInUser.userType === "admin" ||
                    loggedInUser.userType === "zoneManager"
                      ? false
                      : true
                  }
                  type="switch"
                  name="managerType"
                  id="switch-1"
                  value={"vf"}
                  onChange={(ev) => {
                    setIsNPR(!isNPR);
                  }}
                  checked={isNPR ? true : false}
                />
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <label>Société de pose</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setSociete(ev.target.value);
                  }}
                  defaultValue={societe}
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pl-md-" md="3">
              <FormGroup>
                <label>EMAIL</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setClientEmail(ev.target.value);
                  }}
                  name="email"
                  defaultValue={clientEmail}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="pl-md-1" md="3">
              <FormGroup>
                <label>TELEPHONE</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setClientTel(ev.target.value);
                  }}
                  defaultValue={clientTel}
                  type="number"
                />
              </FormGroup>
            </Col>
            <Col className="pl-md-1" md="3">
              <FormGroup>
                <label>MOBILE</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setClientMobile(ev.target.value);
                  }}
                  defaultValue={clientMobile}
                  type="number"
                />
              </FormGroup>
            </Col>
            {loggedInUser.userType !== "installator" && (
              <Col className="pl-md-1" md="3">
                <FormGroup>
                  <label htmlFor="">INSTALLATEUR</label>
                  <Select
                    noOptionsMessage={() => t.NoOptions}
                    className={theme ? "myLightMode3 my-select" : "my-select"}
                    isDisabled={
                      viewType || loggedInUser.userType !== "admin"
                        ? true
                        : false
                    }
                    defaultValue={{ label: installatorName }}
                    // className='my-select'
                    components={animatedComponents}
                    options={installatorOptions}
                    onChange={(selectedProduct) => {
                      setInstallatorName(selectedProduct.label);
                      setInstallator(selectedProduct.value);
                    }}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </div>
        <div className="section">
          <h1>Gestion du client</h1>
          <Row style={{ alignItems: "center" }}>
            <Col md="3">
              <FormGroup className={viewType ? "not-allowed" : ""}>
                <Select
                  noOptionsMessage={() => t.NoOptions}
                  // className = {theme ? 'myLightMode2' : ''}
                  isDisabled={
                    viewType || loggedInUser.userType !== "admin" ? true : false
                  }
                  defaultValue={{ label: clientObj.status }}
                  className={theme ? "myLightMode3 my-select" : "my-select"}
                  components={animatedComponents}
                  options={options}
                  onChange={(selectedProduct) => {
                    setStatus(selectedProduct.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="9" style={{ height: 32 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  lineHeight: 1,
                  alignItems: "center",
                }}
              >
                <CustomInput
                  disabled={
                    loggedInUser.userType !== "installator" ||
                    clientObj.status !== "NON CONFORME"
                  }
                  className="mySwitch-isDate"
                  type="switch"
                  name="managerTyped"
                  id="switch-2"
                  value={"vfd"}
                  onChange={(ev) => {
                    setIsFirstVisit(ev.target.checked);
                  }}
                  checked={isFirstVisit ? true : false}
                />
                <label className="isFirstDate-label">
                  Attestation mise en conformité
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <FormGroup>
                <label>DATE INSTALLATION</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setInstallationDate(ev.target.value);
                  }}
                  defaultValue={moment(installationDate).format("YYYY-MM-DD")}
                  type="date"
                />
              </FormGroup>
            </Col>

            <Col md="3">
              <FormGroup>
                <label>DATE PEC / DATE DIP</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setPecDate(ev.target.value);
                  }}
                  defaultValue={moment(pecDate).format("YYYY-MM-DD")}
                  type="date"
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <label>1E VISITE</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  disabled={
                    viewType || clientObj.status === "A TRAITER" ? true : false
                  }
                  onChange={(ev) => {
                    setFirstVisit(ev.target.value);
                    //clientObj.status = '1E VISITE FIXEE'
                  }}
                  defaultValue={moment(firstVisit).format("YYYY-MM-DD")}
                  type="date"
                />
              </FormGroup>
            </Col>
          </Row>
          {loggedInUser.userType !== "installator" && (
            <Row style={{ alignItems: "center" }}>
              <Col md="3">
                <div
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    lineHeight: 1,
                    alignItems: "center",
                  }}
                >
                  <CustomInput
                    disabled={!isAdmin}
                    type="switch"
                    name="managerType"
                    id="switch-3"
                    value={workFlag}
                    onChange={handleSwitch}
                    checked={workFlag ? true : false}
                  />
                  <label style={{ marginBottom: 0 }}>STA EXTERNE</label>
                </div>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label>STA MISSIONNEE</label>
                  <Select
                    noOptionsMessage={() => t.NoOptions}
                    isDisabled={!workFlag}
                    className={theme ? "myLightMode3 my-select" : "my-select"}
                    value={{ label: staMISSIONNEE?.STA }}
                    components={animatedComponents}
                    options={utilsService.createOptions(stas)}
                    onChange={(selectedProduct) => {
                      setStaMISSIONNEE(selectedProduct.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label>STA TELEPHONE</label>
                  <Input
                    className={theme ? "myDarkInput" : ""}
                    disabled={true}
                    // onChange={(ev) => { setStaMISSIONNEE(ev.target.value) }}
                    value={staMISSIONNEE?.tel}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label>STA ATR</label>
                  <Input
                    className={theme ? "myDarkInput" : ""}
                    disabled={true}
                    value={staMISSIONNEE?.ATR}
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col md="3">
              <FormGroup>
                <label>RAPPORT</label>
                <Select
                  noOptionsMessage={() => t.NoOptions}
                  isDisabled={
                    viewType ||
                    clientObj.status === "A TRAITER" ||
                    clientObj.status === "PEC EN COURS"
                      ? true
                      : false
                  }
                  defaultValue={{ label: clientObj.RAPPORT }}
                  className={theme ? "myLightMode3 my-select" : "my-select"}
                  components={animatedComponents}
                  options={booleanOptions}
                  onChange={(selectedProduct) => {
                    setRAPPORT(selectedProduct.value);
                  }}
                />
              </FormGroup>
            </Col>
            {loggedInUser.userType !== "installator" && (
              <Col>
                <FormGroup>
                  <label>N° DIP</label>
                  <Input
                    disabled={viewType ? true : false}
                    onChange={(ev) => {
                      setNdip(ev.target.value);
                    }}
                    defaultValue={ndip}
                    type="text"
                  />
                </FormGroup>
              </Col>
            )}
            <Col md="3">
              <FormGroup>
                <label>CONFORMITE</label>
                <Select
                  noOptionsMessage={() => t.NoOptions}
                  isDisabled={
                    viewType ||
                    clientObj.status === "A TRAITER" ||
                    clientObj.status === "PEC EN COURS" ||
                    clientObj.status === "1E VISITE FIXEE"
                      ? true
                      : false
                  }
                  defaultValue={{ label: conformite }}
                  className={theme ? "myLightMode3 my-select" : "my-select"}
                  components={animatedComponents}
                  options={booleanOptions}
                  onChange={(selectedProduct) => {
                    setConformite(selectedProduct.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col className="pr-md-1" md="3">
              <FormGroup>
                <label>2E VISITE</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setSecondVisit(ev.target.value);
                  }}
                  defaultValue={moment(secondVisit).format("YYYY-MM-DD")}
                  type="date"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="md-12" bg="10">
              <FormGroup>
                <label>PROBLEMES EVENTUELS</label>
                <Input
                  className={theme ? "myDarkInput" : ""}
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setFreeTxt(ev.target.value);
                  }}
                  defaultValue={freeTxt}
                  type="textarea"
                />
              </FormGroup>
            </Col>
          </Row>
          {loggedInUser.userType !== "installator" && (
            <Row>
              <Col className="col-md-12" bg="10">
                <FormGroup>
                  <label>Commentaires internes</label>
                  <Input
                    className={theme ? "myDarkInput" : ""}
                    disabled={viewType ? true : false}
                    onChange={(ev) => {
                      setInternalComments(ev.target.value);
                    }}
                    defaultValue={internalComments}
                    type="textarea"
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
        </div>
        <div className="section">
          <h1>Détails</h1>
          <div className="products-details">
            {products &&
              products.map((p, idx) => (
                <div key={idx} className="product-line">{`${p.qat} x  ${
                  p.productFamily
                } - ${p.productName} ${p.productModel || ""}`}</div>
              ))}
            {totalPrice && (
              <div className="final-price">
                TOTAL&nbsp;&nbsp;{`${totalPrice} €`}
              </div>
            )}
          </div>
        </div>
        <div className="section">
          <h1>Facturation</h1>
          <Row>
            <Col className="px-md-3" md="3">
              <FormGroup>
                <label>NUM FACTURE</label>
                <Input
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setNfacture(ev.target.value);
                  }}
                  defaultValue={Nfacture}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="px-md-3" md="3">
              <FormGroup>
                <label>NUM FACTURE 2</label>
                <Input
                  disabled={viewType ? true : false}
                  onChange={(ev) => {
                    setNfacture2(ev.target.value);
                  }}
                  defaultValue={Nfacture2}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="pl-md-3" md="3">
              <FormGroup>
                <label>PAYE</label>
                <Select
                  noOptionsMessage={() => t.NoOptions}
                  isDisabled={viewType ? true : false}
                  defaultValue={{ label: clientObj.paid }}
                  className={theme ? "myLightMode3 my-select" : "my-select"}
                  components={animatedComponents}
                  options={booleanOptions}
                  onChange={(selectedProduct) => {
                    clientObj.paid = selectedProduct.value;
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="section actions">
          <Button onClick={onToggle} color="primary" type="button">
            RETOUR
          </Button>
          {(!viewType ||
            (isInstallator && isFirstVisit !== client.isFirstVisit)) && (
            <Button onClick={onSave} color="info" type="button">
              SAUVEGARDER
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}
