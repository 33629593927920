import httpService from './httpService';


export const installatorService = {
    save,
    query,
    getById,
    remove
  }
  
  function query(filterBy = {}) {
    let queryStr = '?';
    for (const key in filterBy) {
      queryStr += `${key}=${filterBy[key]}&`;
    }


    return httpService.get(`installator${queryStr || ''}`);
  }
  

  
  function getById(installatornId) {
    //console.log(installatornId);
    return httpService.get(`installator/${installatornId}`)
  }
  
  function remove(installatornId) {
    return httpService.delete(`installator/${installatornId}`)
  }
  
  function save(installator) {
    //console.log(installator);
    if (installator._id) {
      return httpService.put(`installator/${installator._id}`, installator)
    } else {
      return httpService.post(`installator`, installator);
    }
  }

  