import { productService } from '../../services/productService';


export function loadProducts(filterBy) {
    return async dispatch => {
        const products = await productService.query(filterBy);
        dispatch({ type: 'SET_PRODUCTS', products })
    }
}

export function removeProduct(productId) {
    return async dispatch => {
        await productService.remove(productId)
        dispatch({ type: 'REMOVE_PRODUCT', productId })
    }
}


export function saveProduct(product) {
    return async dispatch => {
        const actionType = product._id ? 'UPDATE_PRODUCT' : 'ADD_PRODUCT';
        product = await productService.save(product);
        if(product.msg) return product.msg 
        else  dispatch({ type: actionType, product })
    }
}




