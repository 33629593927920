import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import "moment/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { toHumanReadable } from "../../helpers/files";
import { saveCrm } from "../../store/actions/crmAction";
import { fileService } from "../../services/fileService";

const columns1 = [
  { id: "fileName", label: "Nom du Fichier", minWidth: 50 },
  { id: "fileType", label: "Type", minWidth: 50 },
  { id: "userName", label: "Utilisateur", minWidth: 50 },
  { id: "size", label: "Taille du fichier", minWidth: 50 },
  { id: "time", label: "Date", minWidth: 50 },
  { id: "download", label: "Télécharger", minWidth: 50 },
  { id: "delete", label: "Supprimer", minWidth: 50 },
];

const columns2 = [
  { id: "fileName", label: "Nom du Fichier", minWidth: 50 },
  { id: "fileType", label: "Type", minWidth: 50 },
  { id: "userName", label: "Utilisateur", minWidth: 50 },
  { id: "size", label: "Taille du fichier", minWidth: 50 },
  { id: "time", label: "Date", minWidth: 50 },
  { id: "download", label: "Télécharger", minWidth: 50 },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export function FilesTable({ crm }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [alertState, setAlertState] = useState(null);

  const onDownloadFile = async (file) => {
    const data = await fileService.getById(file.docId || file.name);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file.name); //or any other extension
    document.body.appendChild(link);
    link.click();
    // window.location.assign(
    //   `https://serveilte.fra1.digitaloceanspaces.com/${file.name}`
    // );
  };

  const onDeleteFile = async (file) => {
    alertEvent("Etes vous sûrs de vouloir supprimer le file ?", file);
  };

  const alertEvent = (title, file) => {
    setAlertState(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => successDelete(file)}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui, je supprime !"
        cancelBtnText="Annuler"
        showCancel
        btnSize=""
      ></SweetAlert>
    );
  };

  const successDelete = (file) => {
    setAlertState(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Supprimé !"
        onConfirm={async () => {
          try {
            await fileService.deleteById(file.docId || file.name);
            crm.files = crm.files.filter(
              (fileData) => fileData._id !== file._id
            );
            dispatch(saveCrm(crm));
            setAlertState(null);
          } catch (error) {
            console.error(error);
            setAlertState(null);
          }
        }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="success"
        btnSize=""
      ></SweetAlert>
    );
  };

  return (
    <div className="files-table-container">
      {alertState}
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader={true} aria-label="sticky table">
            <TableHead className="my-table-head">
              <TableRow className="thRow-container">
                {loggedInUser.userType === "admin"
                  ? columns1.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className="th-row"
                      >
                        {column.label}
                      </TableCell>
                    ))
                  : columns2.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className="th-row"
                      >
                        {column.label}
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody className="files-table-content">
              {crm.files
                ? crm.files.map((file, idx) => (
                    <TableRow key={idx} hover role="checkbox" tabIndex={-1}>
                      <TableCell className="td-file">{file.name}</TableCell>
                      <TableCell className="td-file">{file.fileType}</TableCell>
                      <TableCell className="td-file">
                        {file.uploaderName}
                      </TableCell>
                      <TableCell className="td-file">
                        {toHumanReadable(file.size)}
                      </TableCell>
                      <TableCell className="td-file">
                        {moment(file.date).local("fr").fromNow()}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          onDownloadFile(file);
                        }}
                        className="td-file"
                      >
                        <i className="fas fa-download"></i>
                      </TableCell>
                      {loggedInUser.userType === "admin" && (
                        <TableCell
                          onClick={() => {
                            onDeleteFile(file);
                          }}
                          className="td-file"
                        >
                          <i className="fas fa-trash"></i>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
