import httpService from "./httpService";

export const mailService = {
  sendEmail,
};

function sendEmail(obj) {
  //console.log(obj);
  return httpService.post(`mail`, obj);
}
