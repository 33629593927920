import { sortTypeDate } from "./GenericCols";

export const installatorCols = [
  {
    Header: "DATE PEC / DATE DIP",
    accessor: "pecDate",
    sortType: sortTypeDate,
  },
  {
    Header: "CODE CLIENT",
    accessor: "codeClient",
  },
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "Code Postal",
    accessor: "codePostal",
  },
  {
    Header: "Produits",
    accessor: "products",
  },
  {
    Header: "Puissance",
    accessor: "productsModel",
  },
  {
    Header: "1er VISITE",
    accessor: "firstVisit",
    sortType: sortTypeDate,
  },
  {
    Header: "2eme VISITE",
    accessor: "secondVisit",
    sortType: sortTypeDate,
  },
  {
    Header: "paye",
    accessor: "paid",
  },
  {
    Header: "Statut",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];
