import httpService from "./httpService";

export const fileService = {
  getById,
  save,
  getManuals,
  deleteById,
};

function getById(fileName) {
  //console.log(crmId);
  return httpService.get(`file/${fileName}`, null, true);
}

function save(file) {
  //console.log(file);
  return httpService.post(`file`, file);
}

function getManuals() {
  return httpService.get(`file`);
}

function deleteById(id) {
  return httpService.delete(`file/${id}`);
}
