import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

const COMBO_TYPE = {
  ADDITIONAL_PAC: "ADDITIONAL_PAC",
  CETD_PACK_HS: "CETD_PACK_HS",
  CETD_PACK_S: "CETD_PACK_S",
};

const Step3 = React.forwardRef((props, ref) => {
  const combos = useSelector((state) => state.comboReducer.combos);
  const [totalPrice, setTotalPrice] = useState(0);

  React.useEffect(() => {
    calcTotalPrice();
  }, [props.wizardData.Produits]);

  const ADDITIONAL_PAC = combos.find(
    (c) => c.pack_id === COMBO_TYPE.ADDITIONAL_PAC
  );
  const CETD_PACK_HS = combos.find(
    (c) => c.pack_id === COMBO_TYPE.CETD_PACK_HS
  );
  const CETD_PACK_S = combos.find((c) => c.pack_id === COMBO_TYPE.CETD_PACK_S);

  const loggedInUser =
    props.wizardData?.Informations &&
    props.wizardData?.Informations?.loggedInUser;
  const isInstallator =
    loggedInUser && loggedInUser?.userType === "installator";
  const companyId = isInstallator ? loggedInUser?.companyId : "";
  // const companyName = isInstallator ? loggedInUser?.companyName : "";

  const calcPrice = (row, idx) => {
    const { quantity, familyName, productPrice: price } = row;
    const rows = props.wizardData.Produits;

    if (familyName === "PAC") {
      if (quantity > 1) {
        let additionalPACPrice = ADDITIONAL_PAC.price;
        if (isInstallator && ADDITIONAL_PAC.installators_prices.length > 0) {
          const installatorPrice = ADDITIONAL_PAC.installators_prices.find(
            (pi) => pi.installatorId === companyId
          );
          if (installatorPrice) additionalPACPrice = installatorPrice.price;
        }

        const index = rows.findIndex((r) => r.familyName === "PAC");
        if (index < idx) {
          const prix = quantity * additionalPACPrice;
          return prix;
        } else {
          const prix = price + (quantity - 1) * additionalPACPrice;
          return prix;
        }
      }
    }

    const prix = price * quantity;
    return prix;
  };

  const calcTotalPrice = () => {
    let result = 0;

    const rows = props.wizardData.Produits;
    let pacs = [];
    let cetds = [];

    if (rows && rows.length > 0) {
      const formattedRows = [];
      rows.forEach((r) => {
        const { quantity } = r;
        for (let i = 0; i < quantity; i++) {
          formattedRows.push({ ...r, quantity: 1 });
        }
      });
      formattedRows.forEach((row) => {
        console.log("new row result", result, "row.price:", row.productPrice);
        const { familyName, productPrice, quantity } = row;
        if (familyName === "PAC") pacs.push(row);
        if (familyName === "CETD") cetds.push(row);
        result += quantity * productPrice;
      });
      if (pacs.length >= 1 && cetds.length >= 1) {
        const pacRow = pacs[0];
        const cetdRow = cetds[0];
        const nbComboApply = Math.min((pacs.length, cetds.length));
        const toRemovePrice =
          nbComboApply * (pacRow.productPrice + cetdRow.productPrice);
        result = result - toRemovePrice;

        const isSplit = cetdRow.productName.toLowerCase().includes("split");
        const combo = isSplit ? CETD_PACK_S : CETD_PACK_HS;
        let comboPrice = combo.price;
        if (isInstallator && combo.installators_prices.length > 0) {
          const installatorPrice = combo.installators_prices.find(
            (pi) => pi.installatorId === companyId
          );
          if (installatorPrice) comboPrice = installatorPrice.price;
        }
        result = result + nbComboApply * comboPrice;
      }
      if (pacs.length > 1 && cetds.length < 1) {
        const pacRow = pacs[0];
        const toRemovePrice = pacs.length * pacRow.productPrice;
        result = result - toRemovePrice;

        const combo = ADDITIONAL_PAC;
        let additionalPacPrice = combo.price;
        if (isInstallator && combo.installators_prices.length > 0) {
          const installatorPrice = combo.installators_prices.find(
            (pi) => pi.installatorId === companyId
          );
          if (installatorPrice) additionalPacPrice = installatorPrice.price;
        }
        result =
          result + pacRow.productPrice + (pacs.length - 1) * additionalPacPrice;
      }
    }
    setTotalPrice(result);
  };

  const saveData = () => {
    return totalPrice;
  };

  React.useImperativeHandle(ref, () => ({
    isValidated: undefined,
    state: saveData(),
  }));
  if (
    !props.wizardData.Informations ||
    !props.wizardData.Produits ||
    totalPrice === undefined
  )
    return <div>Chargements</div>;

  const { Informations, Produits } = props.wizardData;
  const { nom, prenom, adress, email, mobile, tel } = Informations;

  return (
    <>
      <form>
        <Row>
          <Col>
            <div>
              <h4>Détails du Client</h4>
              <p>
                NOM COMPLET: {nom} {prenom}
              </p>
              <p>ADRESSE: {adress}</p>
              <p>EMAIL: {email}</p>
              <p>PORTABLE: {mobile}</p>
              <p>TELEPHONE: {tel}</p>
            </div>
          </Col>
          <Col>
            <h4>Détails de la commande</h4>
            <div>
              {Produits.map((row, idx) => {
                const rowPrice = calcPrice(row, idx);
                return (
                  <div key={idx}>
                    <p>Famille de produits : {row.familyName} </p>
                    <p>Catégorie ou marque du produit : {row.productName} </p>
                    <p>Puissance du produit : {row.model} </p>
                    <p>Quantité : {row.quantity} </p>
                    <p>Prix: {rowPrice ? `${rowPrice} €` : "Gratuit"}</p>
                    <hr />
                  </div>
                );
              })}
            </div>
            <p>PRIX TOTAL: {totalPrice} €</p>
          </Col>
        </Row>
      </form>
    </>
  );
});

export default Step3;
