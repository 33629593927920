import React from "react";
import { DragFile } from "./DragFile";
import { FilesTable } from "./FilesTable";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";

export function FilesModal({ client, isVisible, onToggle }) {
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  return (
    <Modal
      isOpen={isVisible}
      toggle={onToggle}
      size="sm"
      className="file-comment-modal"
      scrollable
      centered
    >
      <ModalBody style={{ padding: 0 }}>
        <div
          style={{
            background: "#27293d",
            textAlign: "center",
            color: "#fff",
          }}
        >{`${client.clientFirstName} ${client.clientLastName} - ${client.codeClient}`}</div>
        <FilesTable crm={client} onClose={onToggle} />
        {loggedInUser.userType !== "installator" && (
          <DragFile crm={client} onClose={onToggle} />
        )}
      </ModalBody>
    </Modal>
    // <div>
    //   <div className="modal-files-wrapper" onClick={closeCommentsModal}>
    //     <div
    //       className="modal-files-content"
    //       onClick={(ev) => ev.stopPropagation()}
    //     >
    //       <FilesTable crm={client} />
    //       {loggedInUser.userType !== "installator" && <DragFile crm={client} />}
    //     </div>
    //   </div>
    // </div>
  );
}
