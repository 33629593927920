import React from "react";
import { useSelector } from "react-redux";
import Wizard from "../views/forms/Wizard";

export function CreateClient() {
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);

  if (!loggedInUser) return <div>Loading...</div>;
  return (
    <div className="my-wizard-container">
      <Wizard />
    </div>
  );
}
