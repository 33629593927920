import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "production" ? "/api/" : "//localhost:3030/api/";

var axios1 = axios.create({
  withCredentials: true,
});

const service = {
  get(endpoint, data, isFile = false) {
    return ajax(endpoint, "GET", data, isFile);
  },
  post(endpoint, data) {
    return ajax(endpoint, "POST", data);
  },
  put(endpoint, data) {
    return ajax(endpoint, "PUT", data);
  },
  delete(endpoint, data) {
    return ajax(endpoint, "DELETE", data);
  },
};
export default service;

async function ajax(endpoint, method = "get", data = null, isFile = false) {
  try {
    const params = {
      url: `${BASE_URL}${endpoint}`,
      method,
      data,
    };
    if (isFile) params.responseType = "blob";

    const res = await axios1(params);
    return res.data;
  } catch (err) {
    if (endpoint.includes("login")) {
      const error = JSON.parse(err.request.response);
      throw Error(error ? error.error : err);
    } else {
      throw err;
    }
  }
}
