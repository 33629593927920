import { sortTypeDate } from "./GenericCols";

export const externalCols = [
  // {
  //   Header: "DATE PEC / DATE DIP",
  //   accessor: "pecDate",
  //   sortType: sortTypeDate,
  // },
  {
    Header: "N° DIP",
    accessor: "Nfacture",
  },
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "Code Postal",
    accessor: "codePostal",
  },
  {
    Header: "Installateur",
    accessor: "createdBy",
  },
  {
    Header: "Produits",
    accessor: "products",
  },
  {
    Header: "STA MISSIONNEE",
    accessor: "staMISSIONNEE",
  },
  {
    Header: "1er VISITE",
    accessor: "firstVisit",
    sortType: sortTypeDate,
  },
  {
    Header: "2eme VISITE",
    accessor: "secondVisit",
    sortType: sortTypeDate,
  },
  {
    Header: "N°Facture",
    accessor: "Nfacture",
  },
  {
    Header: "paye",
    accessor: "paid",
  },
  {
    Header: "Statut",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];
