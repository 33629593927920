export const sortTypeDate = (rowA, rowB, id, desc) => {
  const dateStringA = rowA.original[id];
  const dateValuesA = dateStringA.split("/");
  const dateA =
    dateValuesA && dateValuesA.length > 2
      ? new Date(
          parseInt(dateValuesA[2]),
          parseInt(dateValuesA[1]),
          parseInt(dateValuesA[0])
        )
      : null;

  const dateStringB = rowB.original[id];
  const dateValuesB = dateStringB.split("/");
  const dateB =
    dateValuesB && dateValuesB.length > 2
      ? new Date(
          parseInt(dateValuesB[2]),
          parseInt(dateValuesB[1]),
          parseInt(dateValuesB[0])
        )
      : null;

  if (dateA > dateB) return -1;
  if (dateB > dateA) return 1;
  return 0;
};

export const genericCols = [
  // {
  //   Header: "DATE PEC / DATE DIP",
  //   accessor: "pecDate",
  //   sortType: sortTypeDate,
  // },
  {
    Header: "CODE CLIENT",
    accessor: "codeClient",
  },
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "Code Postal",
    accessor: "codePostal",
  },
  {
    Header: "Installateur",
    accessor: "installatorName",
  },
  {
    Header: "Produits",
    accessor: "products",
  },
  {
    Header: "1er VISITE",
    accessor: "firstVisit",
    sortType: sortTypeDate,
  },
  {
    Header: "2eme VISITE",
    accessor: "secondVisit",
    sortType: sortTypeDate,
  },
  {
    Header: "N°Facture",
    accessor: "Nfacture",
  },
  {
    Header: "paye",
    accessor: "paid",
  },
  {
    Header: "Statut",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
];
