import React from "react";
import { useDispatch } from "react-redux";
import { saveUser } from "../store/actions/userAction";

export function TermOfUse({ loggedInUser, agreeToTerms }) {
  const dispatch = useDispatch();
  const agree = () => {
    loggedInUser.legalMention = true;
    dispatch(saveUser(loggedInUser));
    agreeToTerms();
  };

  const disagree = () => {
    window.location.assign("/#/");
  };

  return (
    <div className="modal-lm-wrapper">
      <div className="modal-lm-content flex-col">
        <div className="lm-title flex">
          <h1>Conditions Generales de Vente</h1>
        </div>
        <p>
          En cliquant sur Accepter, vous acceptez les Conditions Generales de
          vente de la plateforme Link-Well. Vous pouvez egalement en prendre
          connaissance en cliquant sur le lien suivant:{" "}
          <a
            href="https://serveilte.fra1.digitaloceanspaces.com/serveliteLegalMention.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Conditions Generales de Vente
          </a>
        </p>
        <section className="flex lm-buttons">
          <button className="btnA" onClick={agree}>
            Accepter
          </button>
          <button className="btnB" onClick={disagree}>
            Refuser
          </button>
        </section>
      </div>
    </div>
  );
}
