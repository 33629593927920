import httpService from './httpService';


export const staService = {
    save,
    query,
    getById,
    remove
  }
  
  function query(filterBy = {}) {
    let queryStr = '?';
    for (const key in filterBy) {
      queryStr += `${key}=${filterBy[key]}&`;
    }


    return httpService.get(`sta${queryStr || ''}`);
  }
  

  
  function getById(staId) {
    //console.log(staId);
    return httpService.get(`sta/${staId}`)
  }
  
  function remove(staId) {
    return httpService.delete(`sta/${staId}`)
  }
  
  function save(sta) {
    //console.log(sta);
    if (sta._id) {
      return httpService.put(`sta/${sta._id}`, sta)
    } else {
      return httpService.post(`sta`, sta);
    }
  }

  