import React, { useState, useEffect, useCallback } from "react";
import { Button } from "reactstrap";
import { fileService } from "../services/fileService";
import { useHistory } from "react-router-dom";
import { toHumanReadable, getFileExtensionFromName } from "../helpers/files";

const loadingMessage = (
  <div
    style={{
      display: "flex",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <p>Chargement...</p>
  </div>
);

const groupBy = (data, field) => {
  return data.reduce((acc, curr) => {
    if (!acc[curr[field]]) acc[curr[field]] = []; //If this type wasn't previously stored
    acc[curr[field]].push(curr);
    return acc;
  }, {});
};

// const FamilyMapping = {
//   PAC: "PAC",
//   CETD: "CETD",
//   CLIM: "CLIM",
//   ["Chaudière"]: "Chaudières",
//   Solaire: "Solaire",
// };

export function Links() {
  const history = useHistory();
  const [manuals, setManuals] = useState();

  useEffect(() => {
    let isSubscribed = true;
    async function init() {
      // TODO: Backend get only manuals sorted by family
      let results = [];
      const response = await fileService.getManuals();
      if (response) {
        response.forEach((f) => {
          results.push({
            name: f.name,
            size: f.fileSize,
            family: f.category,
            url: f.fileUrl,
          });
        });
        const groups = groupBy(results, "family");
        if (isSubscribed) setManuals(groups);
      } else {
        setManuals([]);
      }
    }
    init();
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleGoBack = async () => {
    await history.goBack();
  };

  const getTableHeader = useCallback(() => {
    return (
      <tr>
        <th width="10%">Type</th>
        <th width="70%">Produit</th>
        <th width="10%" style={{ textAlign: "right" }}>
          Taille
        </th>
        <th width="10%"></th>
      </tr>
    );
  }, []);
  const getTableEmptyRow = useCallback(() => {
    return (
      <tr>
        <td colSpan={4}>
          <h3>Aucun document</h3>
        </td>
      </tr>
    );
  }, []);
  const getTypeIcon = useCallback((name) => {
    const extension = getFileExtensionFromName(name);
    switch (extension) {
      case "pdf":
        return "fa-file-pdf";
      case "png":
      case "jpg":
      case "jpeg":
        return "fa-file-jpg";
      case "avi":
      case "mp4":
        return "fa-file-mp3";
      default:
        return "fa-file-pdf";
    }
  }, []);

  if (!manuals) return loadingMessage;

  return (
    <div id="links">
      <div className="header">
        <Button color="primary" onClick={handleGoBack} className="close-btn">
          RETOUR
        </Button>
        <h1>Notices et documents</h1>
      </div>
      <div className="content">
        <div className="card">
          {Object.keys(manuals).map((key) => {
            const docs = manuals[key];
            return (
              <>
                <h2 className="family-name">{`Famille ${key}`}</h2>
                <table>
                  {getTableHeader()}
                  {docs && docs.length > 0
                    ? docs.map((d) => {
                        const { name, size, url } = d;
                        const typeIcon = getTypeIcon(name);
                        return (
                          <tr>
                            <td width="10%">
                              <i className={`fas ${typeIcon} file-type`} />
                            </td>
                            <td width="70%">{name}</td>
                            <td width="10%" align="right">
                              {toHumanReadable(size)}
                            </td>
                            <td width="10%">
                              <div className="actions">
                                <i
                                  className="fas fa-eye action"
                                  onClick={() => window.open(url, "_blank")}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : getTableEmptyRow()}
                </table>
              </>
            );
          })}
        </div>
      </div>
      {/* <div className="flex-col links-container">
        <Button
          onClick={() => {
            window.location.assign("/#/dashboard");
          }}
          className="closePdvCmp"
        >
          Retour
        </Button>
        <h1>Notices et documents</h1>
        <h3>Produits PAC</h3>
        <section className="flex urls">
          <div>
            <p
              onClick={() => {
                viewPdf("Alezio S AWHP 6 MR-3 ECONEGOCE V2");
              }}
            >
              Alezio S AWHP 6 MR-3 ECONEGOCE V2{" "}
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S AWHP 8 MR-2 ECONEGOCE V2");
              }}
            >
              Alezio S AWHP 8 MR-2 ECONEGOCE V2
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S AWHP 11 MR-2 ECONEGOCE V2");
              }}
            >
              Alezio S AWHP 11 MR-2 ECONEGOCE V2
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S AWHP 11 TR-2 ECONEGOCE V2");
              }}
            >
              Alezio S AWHP 11 TR-2 ECONEGOCE V2
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S AWHP 16 MR-2 ECONEGOCE V2");
              }}
            >
              Alezio S AWHP 16 MR-2 ECONEGOCE V2
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S AWHP 16 TR-2 ECONEGOCE V2");
              }}
            >
              Alezio S AWHP 16 TR-2 ECONEGOCE V2
            </p>
          </div>
          <div>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 4,5 MR (1CC)");
              }}
            >
              Alezio S V200 AWHP 4,5 MR (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 4,5 MR (2CC)");
              }}
            >
              Alezio S V200 AWHP 4,5 MR (2CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 6 MR-3 (1CC)");
              }}
            >
              Alezio S V200 AWHP 6 MR-3 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 6 MR-3 (2CC)");
              }}
            >
              Alezio S V200 AWHP 6 MR-3 (2CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 8 MR-2 (1CC)");
              }}
            >
              Alezio S V200 AWHP 8 MR-2 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 8 MR-2 (2CC)");
              }}
            >
              Alezio S V200 AWHP 8 MR-2 (2CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 11 MR-2 (1CC)");
              }}
            >
              Alezio S V200 AWHP 11 MR-2 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 11 MR-2 (2CC)");
              }}
            >
              Alezio S V200 AWHP 11 MR-2 (2CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 11 TR-2 (1CC)");
              }}
            >
              Alezio S V200 AWHP 11 TR-2 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 11 TR-2 (2CC)");
              }}
            >
              Alezio S V200 AWHP 11 TR-2 (2CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 16 MR-2 (1CC)");
              }}
            >
              Alezio S V200 AWHP 16 MR-2 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 16 MR-2 (2CC)");
              }}
            >
              Alezio S V200 AWHP 16 MR-2 (2CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 16 TR-2 (1CC)");
              }}
            >
              Alezio S V200 AWHP 16 TR-2 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("Alezio S V200 AWHP 16 TR-2 (2CC)");
              }}
            >
              Alezio S V200 AWHP 16 TR-2 (2CC)
            </p>
          </div>
          <div>
            <p
              onClick={() => {
                viewPdf("HPI S AWHP 4,5 MR (1CC)");
              }}
            >
              HPI S AWHP 4,5 MR (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("HPI S AWHP 6 MR-3 (1CC)");
              }}
            >
              HPI S AWHP 6 MR-3 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("HPI S AWHP 8 MR-2 (1CC)");
              }}
            >
              HPI S AWHP 8 MR-2 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("HPI S AWHP 11 MR-2 (1CC)");
              }}
            >
              HPI S AWHP 11 MR-2 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("HPI S AWHP 11 TR-2 (1CC)");
              }}
            >
              HPI S AWHP 11 TR-2 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("HPI S AWHP 16 MR-2 (1CC)");
              }}
            >
              HPI S AWHP 16 MR-2 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("HPI S AWHP 16 TR-2 (1CC)");
              }}
            >
              HPI S AWHP 16 TR-2 (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("HPI S AWHP 22 TR (1CC)");
              }}
            >
              HPI S AWHP 22 TR (1CC)
            </p>
            <p
              onClick={() => {
                viewPdf("HPI S AWHP 27 TR (1CC)");
              }}
            >
              HPI S AWHP 27 TR (1CC)
            </p>
          </div>
        </section>
      </div> */}
    </div>
  );
}
