import React from "react";
import { Installator } from "../cmps/signupTypes/Installator";
import { Manager } from "../cmps/signupTypes/Manager";
import { ZoneManager } from "../cmps/signupTypes/ZoneManager";
import {
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Col,
  Button,
} from "reactstrap";

export function Signup() {
  const [horizontalTabs, sethorizontalTabs] = React.useState("profile");

  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault();
    switch (tabState) {
      case "horizontalTabs":
        sethorizontalTabs(tabName);
        break;
      default:
        break;
    }
  };
  const goBack = () => {
    window.location.assign("/#/dashboard");
  };

  return (
    <div id="signup">
      {/* <h2 className="signup-title">Créer un nouvel utilisateur</h2> */}
      <div className="tabs-container">
        <Col md="6">
          <CardBody>
            <Nav className="nav-pills-info flex signup-nav" pills>
              <NavItem>
                <NavLink
                  data-toggle="tab"
                  href="#pablo"
                  className={horizontalTabs === "profile" ? "active" : ""}
                  onClick={(e) =>
                    changeActiveTab(e, "horizontalTabs", "profile")
                  }
                >
                  Installateur
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  data-toggle="tab"
                  href="#pablo"
                  className={horizontalTabs === "settings" ? "active" : ""}
                  onClick={(e) =>
                    changeActiveTab(e, "horizontalTabs", "settings")
                  }
                >
                  Gestionnaire 360
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  data-toggle="tab"
                  href="#pablo"
                  className={horizontalTabs === "options" ? "active" : ""}
                  onClick={(e) =>
                    changeActiveTab(e, "horizontalTabs", "options")
                  }
                >
                  Gestionnaire
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent
              className="tab-space signup-content"
              activeTab={horizontalTabs}
            >
              <TabPane className="my-signup-form1" tabId="profile">
                <Installator />
              </TabPane>
              <TabPane className="my-signup-form2" tabId="settings">
                <Manager />
              </TabPane>
              <TabPane className="my-signup-form3" tabId="options">
                <ZoneManager />
              </TabPane>
            </TabContent>
          </CardBody>
        </Col>
      </div>
      <div className="actions">
        <Button onClick={goBack} color="primary">
          RETOUR
        </Button>
      </div>
    </div>
  );
}
