/*eslint-disable*/
import React, { useState } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import classnames from "classnames";
import Select from "react-select";
import { Button, FormGroup, Input } from "reactstrap";
import { eventBus } from "../../services/eventBusService";
import { t } from "../../constants/text";
import moment from "moment";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// table component
function Table({ columns, data }) {
  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const [filteredCount, setFilteredCount] = useState(false);
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    setFilteredCount(count);
    // if(!count)setFilteredCount(false)
    // else setFilteredCount(true);
    return (
      <FormGroup>
        <Input
          //type="email"
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder={`Rechercher`}
          //placeholder={`${count} Rechercher`}
        />
      </FormGroup>
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    pageOptions,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageSize: 50, pageIndex: 0 },
      autoResetPage: false,
      // autoResetExpanded: false,
      // autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      // autoResetRowState: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
    function () {}
  );
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];

  const handleClickRow = (client) => {
    eventBus.emit("rowClicked", {
      client: client,
    });
  };

  return (
    <>
      <div className="ReactTable -striped -highlight">
        <div className="pagination-container">
          <Button
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="previous-btn"
          >
            PRECEDENT
          </Button>
          <div className="center-selects">
            <Select
              noOptionsMessage={() => t.NoOptions}
              className="react-select info mx-5 w-100"
              classNamePrefix="react-select"
              name="singleSelect"
              value={pageSelect}
              onChange={(value) => {
                gotoPage(value.value);
                handlePageSelect(value);
              }}
              options={pageSelectData.map((prop, key) => {
                return {
                  value: key,
                  label: "Page " + (key + 1),
                };
              })}
              placeholder="Selectionner la page"
            />
            <Select
              noOptionsMessage={() => t.NoOptions}
              className="react-select info mx-5 w-100"
              classNamePrefix="react-select"
              name="singleSelect"
              value={numberOfRows}
              onChange={(value) => {
                setPageSize(value.value);
                setNumberOfRows(value);
                //value.label==='TOUS' ? getLoader()  : ''
              }}
              options={numberOfRowsData.map((prop) => {
                return {
                  // value : prop==='TOUS' ? data.length : prop,
                  // label : prop==='TOUS' ? 'TOUS' : prop + " Lignes",
                  value: prop,
                  label: prop + " Lignes",
                };
              })}
              placeholder="Afficher X lignes"
            />
          </div>
          <Button
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="next-btn"
          >
            SUIVANT
          </Button>
        </div>
        <p style={{ fontWeight: "bolder", marginLeft: "0.25rem" }}>
          Total clients filtrés : {filteredCount}
        </p>
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header my-table-header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames("rt-th rt-resizable-header", {
                      "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                      "-sort-asc": column.isSorted && !column.isSortedDesc,
                      "-sort-desc": column.isSorted && column.isSortedDesc,
                    })}
                  >
                    <div className="rt-resizable-header-content my-row-header">
                      {column.render("Header")}
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {headerGroup.headers.length - 1 === key
                        ? null
                        : column.canFilter
                        ? column.render("Filter")
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody my-table-body">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 },
                    "my-row"
                  )}
                >
                  {row.cells.map((cell) => {
                    const header = cell.column.id;
                    const client = row.original.client;
                    if (header === "installationDate") {
                      const value = cell.value;
                      return (
                        <td
                          {...cell.getCellProps()}
                          onClick={() => handleClickRow(client)}
                          className="rt-td"
                        >
                          {value
                            ? moment(new Date(value)).format("DD/MM/YYYY")
                            : ""}
                        </td>
                      );
                    } else if (
                      header === "products" ||
                      header === "productsModel"
                    ) {
                      const values = cell.value;
                      return (
                        <td
                          {...cell.getCellProps()}
                          onClick={() => handleClickRow(client)}
                          className="rt-td"
                        >
                          <ul>
                            {values &&
                              values.map((v, index) => (
                                <li key={index}>{v}</li>
                              ))}
                          </ul>
                        </td>
                      );
                    } else if (header === "actions") {
                      return (
                        <td {...cell.getCellProps()} className="rt-td">
                          {cell.render("Cell")}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          {...cell.getCellProps()}
                          onClick={() => handleClickRow(client)}
                          className="rt-td"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-bottom"></div>
      </div>
    </>
  );
}

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
