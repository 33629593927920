import httpService from './httpService';


export const userService = {
  login,
  logout,
  signup,
  query,
  getById,
  remove,
  save,
  getById2
}

function query(filterBy = {}) {
  let queryStr = '?';
  for (const key in filterBy) {
    queryStr += `${key}=${filterBy[key]}&`;
  }
  return httpService.get(`user${queryStr || ''}`);
}

function getById2(userId) {
  return httpService.post(`user/${userId}`)
}

function getById(userId) {
  return httpService.get(`user/${userId}`)
}

function remove(userId) {
  return httpService.delete(`user/${userId}`)
}

async function save(user) {
  if (user._id) {
    return httpService.put(`user/${user._id}`, user)
  } else {
    return httpService.post(`user`, user);
  }
}

async function login(userCred) {
  const user = await httpService.post('auth/login', userCred)
  return _handleLogin(user)
}

async function signup(userCred) {
  //console.log(userCred);
  const user = await httpService.post('auth/signup',userCred)
  console.log(user);
  return _handleLogin(user)
}

async function logout() {
  await httpService.post('auth/logout');
  sessionStorage.clear();
}

function _handleLogin(user) {
  //console.log(user);
  sessionStorage.setItem('user', JSON.stringify(user))
  return user;
}
