import React from 'react';
import { eventBus } from '../services/eventBusService';
import { AddInstallatorPreview } from './AddInstallatorPreview';

export function AddInstallator({theme}) {

    const closeModal = () => {
        eventBus.emit("closeAddInstallator");
    };

    return (
        <div className="modal-addInstallator-wrapper" onClick={closeModal}>
            <div className={theme ? 'lightAddInstallator-content modal-addInstallator-content':"modal-addInstallator-content"} onClick={(ev) => ev.stopPropagation()}>
                <AddInstallatorPreview theme={theme} />
            </div>
        </div>
    )
}
