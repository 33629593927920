import React, { useState, useEffect, Suspense } from "react";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import { crmService } from "../services/crmService";
import { Oval } from "react-loading-icons";
const ExportReactTable = React.lazy(() => import("../cmps/ExportReactTable"));

export function ExportTable() {
  const crms = useSelector((state) => state.crmReducer.crms);
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [zoneFlag, setZoneFlag] = useState(false);
  const [allCrms, setAllCrms] = useState([]);

  useEffect(() => {
    let mount = true;
    if (mount && loggedInUser.userType === "zoneManager") {
      getData();
    }
    return () => {
      mount = false;
    };
    // eslint-disable-next-line
  }, [allCrms]);
  const getData = async () => {
    const data = await crmService.query();
    setAllCrms(
      data.map((prop, key) => {
        //COMMON LINES
        let line = {
          id: key,
          client: prop,
          name: `${prop.clientLastName} ${prop.clientFirstName}`,
          adress: prop.clientAdress,
          codePostal: prop.clientPostalCode,
          mobile: prop.clientMobile,
          tel: prop.clientTel,
          Nfacture: prop.Nfacture,
          Nfacture2: prop.Nfacture2,
          email: prop.clientEmail,
          installDate: !!prop.installationDate
            ? prop.installationDate
            : "",
          createdAt: !!prop.createdAt
            ? moment(prop.createdAt).format("DD/MM/YYYY")
            : "",
          status: prop.status,
          societe: prop.societe,
          pecDate: !!prop.pecDate
            ? prop.pecDate
            : "",
          conformite: prop.conformite,
          firstVisit: !!prop.firstVisit
            ? moment(prop.firstVisit).format("DD/MM/YYYY")
            : "",
          secondVisit: !!prop.secondVisit
            ? moment(prop.secondVisit).format("DD/MM/YYYY")
            : "",
          paid: prop.paid,
          subTreated: prop.subTreated,
          rapport: prop.RAPPORT,
          DateDip: prop.DateDip,
          isNPR: prop.isNPR ? "OUI" : "NON",
          codeClient: prop.codeClient,
          isFirstVisit: prop.isFirstVisit ? "OUI" : "NON",
          products: prop.products
            ? prop.products.map((p) => p.productFamily)
            : "",
          productsName: prop.products
            ? prop.products.map((p) => p.productName)
            : "",
          productsModel: prop.products
            ? prop.products.map((p) => p.productModel)
            : "",
          productsQuat: prop.products ? prop.products.map((p) => p.qat) : "",
        };
        if (loggedInUser.userType !== "installator") {
          line.siren = prop.siren ? prop.siren : "";
          line.ndip = prop.ndip;
          line.staMISSIONNEE = prop.staMISSIONNEE ? prop.staMISSIONNEE.STA : "";
          line.installatorName = prop.installatorName;
        }
        return line;
      })
    );
  };

  const [data] = useState(
    (crms || []).map((prop, key) => {
      //COMMON LINES
      let line = {
        id: key,
        client: prop,
        name: `${prop.clientLastName} ${prop.clientFirstName}`,
        adress: prop.clientAdress,
        codePostal: prop.clientPostalCode,
        mobile: prop.clientMobile,
        tel: prop.clientTel,
        Nfacture: prop.Nfacture,
        Nfacture2: prop.Nfacture2,
        email: prop.clientEmail,
        installDate: !!prop.installationDate
          ? prop.installationDate
          : "",
        createdAt: !!prop.createdAt
          ? moment(prop.createdAt).format("DD/MM/YYYY")
          : "",
        status: prop.status,
        societe: prop.societe,
        pecDate: !!prop.pecDate
          ? prop.pecDate
          : "",
        conformite: prop.conformite,
        firstVisit: !!prop.firstVisit
          ? moment(prop.firstVisit).format("DD/MM/YYYY")
          : "",
        secondVisit: !!prop.secondVisit
          ? moment(prop.secondVisit).format("DD/MM/YYYY")
          : "",
        paid: prop.paid,
        subTreated: prop.subTreated,
        rapport: prop.RAPPORT,
        DateDip: prop.DateDip,
        isNPR: prop.isNPR ? "OUI" : "NON",
        codeClient: prop.codeClient,
        isFirstVisit: prop.isFirstVisit ? "OUI" : "NON",
        products: prop.products
          ? prop.products.map((p) => p.productFamily)
          : "",
        productsName: prop.products
          ? prop.products.map((p) => p.productName)
          : "",
        productsModel: prop.products
          ? prop.products.map((p) => p.productModel)
          : "",
        productsQuat: prop.products ? prop.products.map((p) => p.qat) : "",
      };
      if (loggedInUser.userType !== "installator") {
        line.siren = prop.siren ? prop.siren : "";
        line.ndip = prop.ndip;
        line.staMISSIONNEE = prop.staMISSIONNEE ? prop.staMISSIONNEE.STA : "";
        line.installatorName = prop.installatorName;
      }
      return line;
    })
  );

  const GetAllCrms = async () => {
    setZoneFlag(!zoneFlag);
  };

  const onClickReturn = () => {
    window.location.assign("/#/dashboard");
  };
  //if (crms.length===0) return <div>Loaing...</div>
  return (
    <div id="exportTable">
      {/* <h1>export table</h1> */}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          padding: "20px 0",
        }}
      >
        <Button
          onClick={onClickReturn}
          className="btn-fill export-return-btn"
          color="primary"
          type="submit"
        >
          RETOUR
        </Button>
        <Button
          id="xcl-btn-export"
          className="btn-export-container"
          color="success"
        >
          EXPORT XLS
          <ReactHTMLTableToExcel
            color="info"
            id="test-table-xls-button"
            className="btn-export"
            table="table"
            filename="DashboardXls"
            sheet="tablexls"
            buttonText="Download as XLS"
          />
        </Button>
        {loggedInUser.userType === "zoneManager" && (
          <Button
            onClick={GetAllCrms}
            className="btn-fill export-return-btnhhg"
            color={zoneFlag ? "success" : "warning"}
            type="submit"
          >
            TOUS
          </Button>
        )}
      </div>
      <div className="content" style={{ top: "0px !important" }}>
        <Row>
          <Col xs={12} md={12}>
            <Card className="my-export-tableCard">
              <CardHeader></CardHeader>
              <CardBody>
                <Suspense
                  fallback={
                    <Oval className="oval" stroke="#06BCEE" fill="#06BCEE" />
                  }
                >
                  <ExportReactTable
                    data={zoneFlag ? allCrms : data}
                    filterable
                    resizable={false}
                    columns={
                      loggedInUser.userType === "installator"
                        ? [
                            {
                              Header: "NOM",
                              accessor: "name",
                            },
                            {
                              Header: "CODE CLIENT",
                              accessor: "codeClient",
                            },
                            {
                              Header: "ADRESSE",
                              accessor: "adress",
                            },
                            {
                              Header: "CODE POSTAL",
                              accessor: "codePostal",
                            },
                            {
                              Header: "MOBILE",
                              accessor: "mobile",
                            },
                            {
                              Header: "TEL",
                              accessor: "tel",
                            },
                            {
                              Header: "EMAIL",
                              accessor: "email",
                            },
                            {
                              Header: "NRP",
                              accessor: "isNPR",
                            },
                            {
                              Header: "Attestation mise en conformité",
                              accessor: "isFirstVisit",
                            },
                            {
                              Header: "FAMILLE PRODUIT",
                              accessor: "products",
                            },
                            {
                              Header: "PRODUIT",
                              accessor: "productsName",
                            },
                            {
                              Header: "PUISSANCE",
                              accessor: "productsModel",
                            },
                            {
                              Header: "QUANTITE",
                              accessor: "productsQuat",
                            },
                            {
                              Header: "DATE INSTALLATION",
                              accessor: "installDate",
                            },
                            {
                              Header: "DATE ENVOIE PEC",
                              accessor: "createdAt",
                            },
                            {
                              Header: "STATUT",
                              accessor: "status",
                            },
                            {
                              Header: "PEC DATE",
                              accessor: "pecDate",
                            },
                            {
                              Header: "CONFORMITE",
                              accessor: "conformite",
                            },

                            {
                              Header: "1E VISITE",
                              accessor: "firstVisit",
                            },
                            {
                              Header: "2E VISITE",
                              accessor: "secondVisit",
                            },
                            {
                              Header: "NUM FACTURE",
                              accessor: "Nfacture",
                            },
                            {
                              Header: "NUM FACTURE 2",
                              accessor: "Nfacture2",
                            },
                            {
                              Header: "PAYE",
                              accessor: "paid",
                            },
                            {
                              Header: "STA EXTERNE",
                              accessor: "subTreated",
                            },
                            {
                              Header: "RAPPORT",
                              accessor: "rapport",
                            },
                            {
                              Header: "DATE DIP",
                              accessor: "DateDip",
                            },
                          ] /*ADMIN ADMIN ADMIN ADMIN ADMIN ADMIN*/
                        : [
                            {
                              Header: "NOM",
                              accessor: "name",
                            },
                            {
                              Header: "CODE CLIENT",
                              accessor: "codeClient",
                            },
                            {
                              Header: "ADRESSE",
                              accessor: "adress",
                            },
                            {
                              Header: "CODE POSTAL",
                              accessor: "codePostal",
                            },
                            {
                              Header: "STA EXTERNE",
                              accessor: "workerType",
                            },
                            {
                              Header: "INSTALLATEUR",
                              accessor: "installatorName",
                            },
                            {
                              Header: "SOCIETE DE POSE",
                              accessor: "societe",
                            },
                            {
                              Header: "SIREN",
                              accessor: "siren",
                            },
                            {
                              Header: "MOBILE",
                              accessor: "mobile",
                            },
                            {
                              Header: "TEL",
                              accessor: "tel",
                            },
                            {
                              Header: "EMAIL",
                              accessor: "email",
                            },
                            {
                              Header: "N° DIP",
                              accessor: "ndip",
                            },
                            {
                              Header: "NRP",
                              accessor: "isNPR",
                            },
                            {
                              Header: "Att. MEC",
                              accessor: "isFirstVisit",
                            },
                            {
                              Header: "FAMILLE PRODUIT",
                              accessor: "products",
                            },
                            {
                              Header: "PRODUIT",
                              accessor: "productsName",
                            },
                            {
                              Header: "PUISSANCE",
                              accessor: "productsModel",
                            },
                            {
                              Header: "QUANTITE",
                              accessor: "productsQuat",
                            },
                            {
                              Header: "DATE INSTALLATION",
                              accessor: "installDate",
                            },
                            {
                              Header: "DATE ENVOIE PEC",
                              accessor: "createdAt",
                            },
                            {
                              Header: "STATUT",
                              accessor: "status",
                            },
                            {
                              Header: "COMMENTAIRES INTERNES",
                              accessor: "internalComments",
                            },
                            {
                              Header: "PEC DATE",
                              accessor: "pecDate",
                            },
                            {
                              Header: "CONFORMITE",
                              accessor: "conformite",
                            },

                            {
                              Header: "1E VISITE",
                              accessor: "firstVisit",
                            },
                            {
                              Header: "2E VISITE",
                              accessor: "secondVisit",
                            },
                            {
                              Header: "N° FACTURE",
                              accessor: "Nfacture",
                            },
                            {
                              Header: "N° FACTURE 2",
                              accessor: "Nfacture2",
                            },
                            {
                              Header: "PAYE",
                              accessor: "paid",
                            },
                            {
                              Header: "STA EXTERNE",
                              accessor: "subTreated",
                            },
                            {
                              Header: "RAPPORT",
                              accessor: "rapport",
                            },
                            {
                              Header: "STA MISSIONNEE",
                              accessor: "staMISSIONNEE",
                            },
                            {
                              Header: "DATE DIP",
                              accessor: "DateDip",
                            },
                          ]
                    }
                    defaultPageSize={200}
                    showPaginationTop={true}
                    showPaginationBottom
                    className="-striped -highlight my-export-table"
                  />
                </Suspense>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
