import React, { useState } from "react";
import ReactWizard from "react-bootstrap-wizard";
import { Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { saveCrm } from "../../store/actions/crmAction";
import SweetAlert from "react-bootstrap-sweetalert";
import { utilsService } from "../../services/utils";
// wizard steps
import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";

const Wizard = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);
  const [alertState, setAlertState] = useState(null);

  const alertEvent = (title) => {
    setAlertState(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => {
          setAlertState(null);
          window.location.assign("/#/dashboard");
        }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      ></SweetAlert>
    );
  };

  const steps = [
    {
      stepName: "Informations",
      stepIcon: "tim-icons icon-single-02",
      component: Step1,
    },
    {
      stepName: "Produits",
      stepIcon: "tim-icons icon-settings-gear-63",
      component: Step2,
    },
    {
      stepName: "Récapitulatif",
      stepIcon: "tim-icons icon-delivery-fast",
      component: Step3,
    },
  ];

  const onSubmitClient = (item) => {
    const products = (item.Produits || []).map((p) => ({
      productFamily: p.familyName,
      productName: p.productName,
      price: p.productPrice,
      productModel: p.model,
      qat: p.quantity,
    }));

    const clientObj = {
      clientFirstName: item.Informations.prenom,
      clientLastName: item.Informations.nom,
      clientAdress: item.Informations.adress,
      clientPostalCode: item.Informations.codePostal,
      clientTel: item.Informations.tel,
      clientMobile: item.Informations.mobile,
      clientEmail: item.Informations.email,
      installationDate: item.Informations.installationDate,
      freeTxt: item.Informations.freeTxt,
      societe: item.Informations.societe,
      products: products,
      totalPrice: item.Récapitulatif,
      createdBy: loggedInUser.fullName,
      createdByEmail: loggedInUser.email,
      createdAt: Date.now(),
      status: "A TRAITER",
      pecDate: "",
      ndip: "",
      conformite: "",
      firstVisit: "",
      secondVisit: "",
      Nfacture: "",
      Nfacture2: "",
      paid: "",
      comments: [],
      files: [],
      subTreated: utilsService.checkZoneArea(item.Informations.codePostal)
        ? false
        : true,
      RAPPORT: "",
      staMISSIONNEE: "",
      DateDip: "",
      dip2eme: "",
      codeClient: "",
      workerType: utilsService.checkZoneArea(item.Informations.codePostal)
        ? "internal"
        : "external",
      isNPR: false,
      isFirstVisit: false,
      internalComments: "",
      installator:
        loggedInUser.userType === "installator" ? loggedInUser._id : "",
      installatorName:
        loggedInUser.userType === "installator" ? loggedInUser.companyName : "",
      siren: loggedInUser.userType === "installator" ? loggedInUser.siren : "",
    };
    //console.log(clientObj);
    dispatch(saveCrm(clientObj));
    alertEvent("Nouveau client créé avec succès");
    //window.location.assign('/#/dashboard/');
  };

  return (
    <div>
      {alertState}

      <Col className="mr-auto ml-auto my-wizard" md="10">
        <ReactWizard
          steps={steps}
          navSteps
          validate
          title="Ajouter un nouveau client"
          headerTextCenter
          finishButtonClasses="btn-wd btn-info"
          finishButtonText="Terminer"
          nextButtonClasses="btn-wd btn-info btn-next"
          previousButtonClasses="btn-wd btn-primary btn-prev"
          progressbar
          color="blue"
          nextButtonText="Suivant"
          previousButtonText="Precedent"
          finishButtonClick={(item) => {
            onSubmitClient(item);
          }}
        />
      </Col>
    </div>
  );
};

export default Wizard;
