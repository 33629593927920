import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { utilsService } from "../../../services/utils";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  FormGroup,
  Button,
} from "reactstrap";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import { localStorageService } from "../../../services/localStorageService";
import { useSelector } from "react-redux";
import { t } from "../../../constants/text";
//import ReactDatetime from "react-datetime";

const Step1 = React.forwardRef((props, ref) => {
  const loggedInUser = useSelector((state) => state.userReducer.loggedInUser);

  //form inputs state
  const [emailState, setEmailState] = useState("");
  const [nomState, setNomState] = useState("");
  const [prenomState, setPrenomState] = useState("");
  const [adressState, setAdressState] = useState("");
  const [telephoneState, setTelephoneState] = useState("");
  const [mobileState, setMobileState] = useState("");
  const [installDateState, setInstallDateState] = useState("");
  const [freeTxtState, setFreeTxtState] = useState("");
  const [societeState, setSocieteState] = useState("");
  const [theme, setTheme] = useState(null);

  //form inputs
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adress, setAdress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [mobile, setMobile] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [installDate, setInstallDate] = useState("");
  const [freeTxt, setFreeTxt] = useState("");
  const [societe, setSociete] = useState("");

  //inputs focus
  const [nomFocus, setNomFocus] = useState(false);
  const [prenomFocus, setPrenomFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [mobileFocus, setMobileFocus] = useState(false);
  const [telFocus, setTelFocus] = useState(false);
  const [installDateFocus] = useState(false);
  const [freeTxtFocus, setFreeTxtFocus] = useState(false);
  const [societeFocus, setSocieteFocus] = useState("");

  const _getCodePostal = async (adress) => {
    const data = await geocodeByPlaceId(adress.value.place_id);
    const addressParts = data[0].address_components;
    const zipCodePart = addressParts.find((p) => p.types[0] === "postal_code");
    const zipCode = zipCodePart?.long_name || "";
    setCodePostal(zipCode);
  };
  useEffect(() => {
    let mount = true;
    if (mount) {
      const userPref = localStorageService.loadFromStorage("theme");
      //console.log('vnfvnkfk',userPref.theme);
      if (userPref) setTheme(userPref.theme);
    }
    return () => {
      mount = false;
    };
  }, []);

  /*eslint-disable-next-line*/
  const isValidated = () => {
    if (
      nomState === "has-success" &&
      prenomState === "has-success" &&
      adressState === "has-success" &&
      emailState === "has-success" &&
      mobileState === "has-success" &&
      societeState === "has-success" &&
      installDateState === "has-success"
    ) {
      return true;
    }

    if (prenomState !== "has-success") setPrenomState("has-danger");
    if (nomState !== "has-success") setNomState("has-danger");
    if (emailState !== "has-success") setEmailState("has-danger");
    if (adressState !== "has-success") setAdressState("has-danger");
    if (mobileState !== "has-success") setMobileState("has-danger");
    if (installDateState !== "has-success" || !installDate)
      setInstallDateState("has-danger");
    if (!societe) setSocieteState("has-danger");

    return false;
  };

  const saveData = () => {
    const firstData = {
      nom: nom,
      prenom: prenom,
      adress: adress.label,
      codePostal: codePostal,
      email: email,
      tel: telephone,
      mobile: mobile,
      installationDate: installDate,
      freeTxt: freeTxt,
      societe: societe,
      loggedInUser: loggedInUser,
    };

    return firstData;
  };
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state: saveData(),
  }));

  const handleAdressChange = (value) => {
    console.log("value", value.value);
    if (
      // value.value.types[0] !== "street_address" &&
      // value.value.types[0] !== "bar" &&
      // value.value.types[0] !== "neighborhood" &&
      // value.value.types[0] !== "premise"
      value.value.types[0] === "locality" ||
      value.value.types[0] === "postal_code"
      // value.value.types[0] === "geocode" ||
      // value.value.types[0] === "route"
      // value.value.types[0] !== "street_address" &&
      // value.value.types[0] !== "bar" &&
      // value.value.types[0] !== "neighborhood" &&
      // value.value.types[0] !== "premise"
      // value.value.types[0] !== "point_of_interest" &&
      // value.value.types[0] !== 'car_dealer' &&
      // value.value.types[0] !== 'store' &&
      // value.value.types[0] !== 'establishment'
    ) {
      setAdress("");
      setAdressState("has-danger");
    } else {
      setAdress(value);
      _getCodePostal(value);
      setAdressState("has-success");
    }
  };
  const goBack = () => {
    window.location.assign("/#/dashboard");
  };

  return (
    <>
      <Button
        className="btn"
        color="primary"
        style={{
          position: "absolute",
          zIndex: 1,
          left: 0,
          bottom: 0,
          textTransform: "uppercase",
          color: "#fff",
        }}
        onClick={() => goBack()}
      >
        RETOUR
      </Button>
      <h3 className="info-text">Détails du Client</h3>
      <Row className="justify-content-center mt-5">
        <Col sm="5">
          <label>NOM *</label>
          <InputGroup
            className={classnames(nomState, {
              "input-group-focus": nomFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-single-02" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              autoComplete="off"
              name="nom"
              type="text"
              onFocus={(e) => setNomFocus(true)}
              onBlur={(e) => setNomFocus(false)}
              onChange={(ev) => {
                setNom(ev.target.value);
                if (utilsService.verifyLength(ev.target.value, 2)) {
                  setNomState("has-success");
                } else {
                  setNomState("has-danger");
                }
              }}
            />
            {nomState === "has-danger" ? (
              <label className="error">
                Merci de renseigner un nom valide.
              </label>
            ) : null}
          </InputGroup>

          <label>PRENOM *</label>
          <InputGroup
            className={classnames(prenomState, {
              "input-group-focus": prenomFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-caps-small" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              autoComplete="off"
              name="prenom"
              type="text"
              onFocus={(e) => setPrenomFocus(true)}
              onBlur={(e) => setPrenomFocus(false)}
              onChange={(ev) => {
                setPrenom(ev.target.value);
                if (utilsService.verifyLength(ev.target.value, 2)) {
                  setPrenomState("has-success");
                } else {
                  setPrenomState("has-danger");
                }
              }}
            />
            {prenomState === "has-danger" ? (
              <label className="error">
                Merci de renseigner un prénom valide.
              </label>
            ) : null}
          </InputGroup>

          <label>TELEPHONE</label>
          <InputGroup
            className={classnames(telephoneState, {
              "input-group-focus": telFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-headphones" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              autoComplete="off"
              name="telephone"
              type="number"
              onFocus={(e) => setTelFocus(true)}
              onBlur={(e) => setTelFocus(false)}
              onChange={(ev) => {
                setTelephone(ev.target.value);
                if (utilsService.verifyPhoneNum(ev.target.value)) {
                  setTelephoneState("has-success");
                } else {
                  setTelephoneState("has-danger");
                }
              }}
            />
            {/* {telephoneState === "has-danger" ? (
              <label className="error">Ce champ est obligatoire.</label>
            ) : null} */}
          </InputGroup>

          <label>PORTABLE *</label>
          <InputGroup
            className={classnames(mobileState, {
              "input-group-focus": mobileFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-mobile" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              autoComplete="off"
              name="telephone"
              type="number"
              onFocus={(e) => setMobileFocus(true)}
              onBlur={(e) => setMobileFocus(false)}
              onChange={(ev) => {
                setMobile(ev.target.value);
                if (utilsService.verifyPhoneNum(ev.target.value)) {
                  setMobileState("has-success");
                } else {
                  setMobileState("has-danger");
                }
              }}
            />
            {mobileState === "has-danger" ? (
              <label className="error">Ce champ est obligatoire</label>
            ) : null}
          </InputGroup>
        </Col>
        <Col sm="5">
          <FormGroup className={`has-label ${adressState} adress`}>
            <label>ADRESSE *</label>
            <div className={theme ? "myLightMode" : ""}>
              <GooglePlacesAutocomplete
                autocompletionRequest={{
                  componentRestrictions: { country: "fr" },
                }}
                autoFillOnNotFound={true}
                listEmptyComponent={() => <div>{t.NoOptions}</div>}
                apiOptions={{ language: "fr", region: "fr" }}
                selectProps={{
                  noOptionsMessage: () => t.NoOptions,
                  placeholder: "....",
                  value: adress,
                  onChange: handleAdressChange,
                }}
              />
            </div>
            {adressState === "has-danger" ? (
              <label className="error">
                Merci de renseigner une adresse valide.
              </label>
            ) : null}
          </FormGroup>

          <label>EMAIL *</label>
          <InputGroup
            className={classnames(emailState, {
              "input-group-focus": emailFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-email-85" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              autoComplete="off"
              name="email"
              type="email"
              onFocus={(e) => setEmailFocus(true)}
              onBlur={(e) => setEmailFocus(false)}
              onChange={(ev) => {
                setEmail(ev.target.value);
                if (utilsService.verifyEmail(ev.target.value)) {
                  setEmailState("has-success");
                } else {
                  setEmailState("has-danger");
                }
              }}
            />
            {emailState === "has-danger" ? (
              <label className="error">
                Merci de renseigner un email valide.
              </label>
            ) : null}
          </InputGroup>

          <label>Société de pose *</label>
          <InputGroup
            className={classnames(societeState, {
              "input-group-focus": societeFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-single-02" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              autoComplete="off"
              name="societe"
              type="text"
              onFocus={(e) => setSocieteFocus(true)}
              onBlur={(e) => setSocieteFocus(false)}
              onChange={(ev) => {
                setSociete(ev.target.value);
                if (utilsService.verifyLength(ev.target.value, 2)) {
                  setSocieteState("has-success");
                } else {
                  setSocieteState("has-danger");
                }
              }}
            />
            {societeState === "has-danger" ? (
              <label className="error">
                Merci de renseigner un nom valide.
              </label>
            ) : null}
          </InputGroup>

          <label>DATE D INSTALLATION *</label>
          <InputGroup
            className={classnames(installDateState, {
              "input-group-focus": installDateFocus,
            })}
          >
            <DatePicker
              className="form-control installDate"
              onChange={(time) => {
                //console.log('time', time);
                setInstallDate(time);
                setInstallDateState("has-success");
              }}
              //minDate={new Date()}
              maxDate={new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)}
              value={installDate}
              format="dd/MM/yyyy"
            />
            {installDateState === "has-danger" ? (
              <label className="error">Ce champ est obligatoire.</label>
            ) : null}
          </InputGroup>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm="10">
          <label>PROBLEMES EVENTUELS</label>
          <InputGroup
            className={classnames(freeTxtState, {
              "input-group-focus": freeTxtFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-notes" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              autoComplete="off"
              name="freeTxt"
              type="text"
              onFocus={(e) => setFreeTxtFocus(true)}
              onBlur={(e) => setFreeTxtFocus(false)}
              onChange={(ev) => {
                setFreeTxt(ev.target.value);
                if (utilsService.verifyLength(ev.target.value, 2)) {
                  setFreeTxtState("has-success");
                } else {
                  setFreeTxtState("has-danger");
                }
              }}
            />
            {freeTxtState === "has-danger" ? (
              <label className="error">Ce champ est obligatoire</label>
            ) : null}
          </InputGroup>
        </Col>
      </Row>
    </>
  );
});

export default Step1;
