const eventMap = new Map();

function on(eventName, listener) {
  const callListener = ({ detail }) => {
    listener(detail);
  };
  if (!eventMap.has(eventName)) {
    window.addEventListener(eventName, callListener);
    eventMap.set(eventName, callListener);
  }
  return () => {
    window.removeEventListener(eventName, callListener);
  };
}

function emit(eventName, data) {
  window.dispatchEvent(new CustomEvent(eventName, { detail: data }));
}

function remove(eventName) {
  if (eventMap.has(eventName)) {
    const callback = eventMap.get(eventName);
    window.removeEventListener(eventName, callback);
    eventMap.delete(eventName);
  }
}

function removeMultiple(eventNames) {
  eventNames.forEach((e) => remove(e));
}

export const EVENT_BUS_EVENTS = {
  readMessages: "readMessage",
};

export const eventBus = { on, remove, removeMultiple, emit };
