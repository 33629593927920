const initialState = {
    products: null,
  }


  export function productReducer(state = initialState, action) {
    switch (action.type) {
  
      case 'SET_PRODUCTS':
        return {
          ...state,
          products: action.products
        }

        case 'REMOVE_PRODUCT':
      return { ...state, products: state.products.filter(product => product._id !== action.productId) }

      case 'UPDATE_PRODUCT':
      const idx = state.products.findIndex(product => product._id === action.product._id)
      const updatedProducts = [...state.products]
      updatedProducts[idx] = action.product;
      return { ...state, products: updatedProducts }

      case 'ADD_PRODUCT':
      return { products: [...state.products, action.product], ...state }


      default:
        return state
    }
  }