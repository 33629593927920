import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/userAction";
import { clearCrms } from "../../store/actions/crmAction";
import NotificationAlert from "react-notification-alert";
import { notificationService } from "../../services/notificationService";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";

let mount = null;
const AdminNavbar = (props) => {
  const [notis] = useState([]);
  const dispatch = useDispatch();
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalSearch, setModalSearch] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  const notificationAlertRef = React.useRef(null);
  // const [lmFlag, setLmFlag] = useState(props.loggedInUser.usertType==='installator' ?props.loggedInUser.legalMention : ' ');

  useEffect(() => {
    mount = true;
    if (mount) {
      window.addEventListener("resize", updateColor);
      var myInterval = null;
      myInterval = setInterval(() => {
        getNotifs();
      }, 60000);
    }
    return () => {
      mount = false;
      clearInterval(myInterval);
      window.removeEventListener("resize", updateColor);
    };
    // eslint-disable-next-line
  }, []);

  const getNotifs = async () => {
    const data = await notificationService.query();
    const filtered = data.filter((not) => {
      if (props.loggedInUser.userType === "installator")
        return not.sendTo[0] === props.loggedInUser.companyName;
      else return not.sendTo[0] === props.loggedInUser._id;
    });
    if (filtered.length > 0) var not = filtered[filtered.length - 1];
    if (filtered.length > notis.length && !not.isRead) {
      notis.push(filtered);
      not.isRead = true;
      notificationService.save(not);
      let options = {};
      options = {
        place: "tc",
        message:
          filtered[filtered.length - 1].txt.firstLine +
          "\n" +
          filtered[filtered.length - 1].txt.secondLine,
        type: "success",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(options);
    }
  };

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };

  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };

  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setModalSearch(!modalSearch);
  };

  const onLogOut = () => {
    //clearInterval(interv)
    localStorage.removeItem("isAuthenticated");
    dispatch(logout());
    dispatch(clearCrms());
    window.location.assign("/#/");
  };

  // const agreeToTerms = ()=> {
  //   setLmFlag(true);
  // }

  //if(loggedInUser===null) return <div>loading...</div>
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: window.location.pathname.indexOf("full-screen-map") === -1,
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209599"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <p className="userName">{props.loggedInUser.fullName}</p>
              {/* <InputGroup className="search-bar" tag="li">
                <Button
                  color="link"
                  data-target="#searchModal"
                  data-toggle="modal"
                  id="search-button"
                  onClick={toggleModalSearch}
                >
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Search</span>
                </Button>
              </InputGroup> */}

              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    <img
                      alt="avatar"
                      className="avatar"
                      src={
                        props.loggedInUser && props.loggedInUser.logo
                          ? `https://serveilte.fra1.digitaloceanspaces.com/${props.loggedInUser.logo}`
                          : require("../../assets/img/mike.jpg").default
                      }
                    />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem
                      className="nav-item"
                      onClick={props.selectUserProfile}
                    >
                      Mon Compte
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem onClick={onLogOut} className="nav-item">
                      Déconnexion
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AdminNavbar;
